@use "../global" as g;
@use "../foundation" as f;

#artists {
  
  @include g.mq(md) {
    margin-bottom: f.px2vw(200,1920);
  }
  
  @include g.mq(sm) {
    margin-bottom: f.px2vw(120);
  }
  
  .artists-mv {
    @include g.mq(md) {
      padding-top: f.px2vw(126,1920);
    }
    
    img {
      width: 100%;
    }
  }
  .artists-wrap {
    
    @include g.mq(md) {
      margin-top: 110px;
    }
    
    @include g.mq(sm) {
      margin-top: f.px2vw(49);
    }
    
    &__title {
      position: relative;
    }
    
    &__content {
      @include g.mq(md) {
        max-width: f.px2vw(1479,1920);
        margin: f.px2vw(74,1920) auto 0;
      }
      
      @include g.mq(sm) {
        margin-top: f.px2vw(17);
        padding: 0 f.px2vw(10) 0 f.px2vw(20);
      }
      
      .content-box {
        position: relative;
        
        @include g.mq(md) {
          margin-top: f.px2vw(74,1920);
        }
        
        @include g.mq(sm) {
          margin-top: f.px2vw(80);
        }
        
        .box {
          position: relative;
          
          @include g.mq(md) {
            display: flex;
            justify-content: space-between;   
            align-items: flex-start; 
          }
          
          .box-message {
            
            @include g.mq(md) {
              flex: 0 1 100%;
              margin-top: f.px2vw(21,1920);
            }
            
            &__date {
              position: relative;
              opacity: 0;
              font-weight: bold;
              line-height: 1.4;
              
              @include g.mq(md) {
                font-size: f.px2vw(28,1920);
                padding-left: f.px2vw(70,1920);
              }
              
              @include g.mq(sm) {
                font-size: f.px2vw(14);
              }
              
              &.fade-next-date {
                bottom: f.px2vw(-50,1920);
                
                /*# animation
                ================*/
                animation-name:fade-next-wrap;
                animation-delay: 1s;
                animation-duration:1s;
                animation-timing-function: cubic-bezier(0.22, 1, 0.36, 1);
                animation-fill-mode:forwards; 
              }
            }
            
            &__name {
              
              @include g.mq(md) {
                background: url("../img/top/next_bg_01.png");
                background-size: cover;
                background-repeat: no-repeat;    
                width: f.px2vw(1,1920);
                height: f.px2vw(191,1920);
                margin-top: f.px2vw(-16,1920);
              }
              
              @include g.mq(sm) {
                background: url("../img/top/sp/next_bg_01.png");
                background-size: cover;
                background-repeat: no-repeat;    
                width: f.px2vw(1);
                height: f.px2vw(77);
                transform: translateX(f.px2vw(-20));
                margin-top: f.px2vw(-4,1920);
              }
              
              .name-txt {
                opacity: 0;
                position: relative;
                
                @include g.mq(md) {
                  padding: f.px2vw(56,1920) 0 0 f.px2vw(142,1920);
                  font-size: f.px2vw(48,1920);
                  bottom: f.px2vw(-50,1920);
                }
                
                @include g.mq(sm) {
                  font-size: f.px2vw(22);
                  margin-bottom: f.px2vw(-20);
                  padding: f.px2vw(19) 0 0 f.px2vw(43);    
                }
                
                .cat-name {
                  display: block;
                  line-height: 1.2;
                  
                  @include g.mq(md) {
                    font-size: f.px2vw(20,1920);
                    padding-bottom: f.px2vw(2,1920);
                  }
                  
                  @include g.mq(sm) {
                    font-size: f.px2vw(10);
                  }
                }
                
                .en-name {
                  
                  @include g.mq(md) {
                    font-size: f.px2vw(18,1920);
                    padding-left: f.px2vw(11,1920);
                  }
                  
                  @include g.mq(sm) {
                    font-size: f.px2vw(10);
                  }
                }
              }
              
              /*# animation
              ==============================================================*/
              &.fade-next-name {
                width: 0px;
                
                /*# animation
                ================*/
                animation-name:fade-next-name__bg;
                animation-delay: 1.5s;
                animation-duration:1s;
                animation-timing-function: cubic-bezier(0.22, 1, 0.36, 1);
                animation-fill-mode:forwards; 
                
                .name-txt {
                  /*# animation
                  ================*/
                  animation-name:fade-next-name__txt;
                  animation-delay: 2s;
                  animation-duration:1s;
                  animation-timing-function: cubic-bezier(0.22, 1, 0.36, 1);
                  animation-fill-mode:forwards; 
                }
              }
              @include g.mq(md) {
                @keyframes fade-next-name__bg{
                  0% {
                    width: 0px;
                  }
                  
                  100% {
                    width: f.px2vw(742,1920);
                  }
                }
              }
              @include g.mq(sm) {
                @keyframes fade-next-name__bg{
                  0% {
                    width: 1px;
                  }
                  
                  100% {
                    width: f.px2vw(301);
                  }
                }
              }
              @include g.mq(md) {
                @keyframes fade-next-name__txt{
                  0% {
                    opacity: 0;
                    bottom: f.px2vw(-50,1920);
                  }
                  
                  100% {
                    opacity: 1;
                    bottom: 0;
                  }
                }
              }
              @include g.mq(sm) {
                @keyframes fade-next-name__txt{
                  0% {
                    opacity: 0;
                    bottom: f.px2vw(-50);
                  }
                  
                  100% {
                    opacity: 1;
                    bottom: 0;
                  }
                }
              }
            }
            
            &__wrap {
              position: relative;
              opacity: 0;
              
              @include g.mq(md) {
                padding-left: f.px2vw(69,1920);
                margin-top: f.px2vw(10,1920);
              }
              
              @include g.mq(sm) {
                margin-top: f.px2vw(10);
              }
              
              .wrap-title {
                @include g.mq(md) {
                  font-size: f.px2vw(30,1920);
                  line-height: 1.2;
                }
                
                @include g.mq(sm) {
                  font-size: f.px2vw(18);
                  line-height: 1.3;
                }
              }
              
              .wrap-txt {
                font-weight: 400;
                
                @include g.mq(md) {
                  font-size: f.px2vw(26,1920);
                  line-height: 1.538;
                  padding: 0 f.px2vw(65,1920) 0 0;    
                }
                
                @include g.mq(sm) {
                  font-size: f.px2vw(12);
                  line-height: 1.5;
                  margin-top: f.px2vw(13);
                  padding-right: f.px2vw(60);
                }
              }

              .broadcast-txt {
                font-weight: 400;
                
                @include g.mq(md) {
                  font-size: f.px2vw(22,1920);
                  margin: f.px2vw(40,1920) 0 f.px2vw(5,1920);
                }
                
                @include g.mq(sm) {
                  font-size: f.px2vw(11);
                  margin-top: f.px2vw(20);
                  margin: f.px2vw(20) 0 f.px2vw(5);
                }
              }
              
              .view-video-link {
                display: inline-block;
                position: relative;
                cursor: pointer;
                font-weight: 500;
                border-bottom: 1px solid #fff;
                
                @include g.mq(md) {
                  font-size: f.px2vw(27,1920);
                  padding-left: f.px2vw(30,1920);
                  padding-bottom: f.px2vw(5,1920);
                  margin-right: f.px2vw(35,1920);
                  margin-top: f.px2vw(10,1920);
                }
                
                @include g.mq(sm) {
                  font-size: f.px2vw(12);
                  padding-left: f.px2vw(18);
                  padding-bottom: f.px2vw(2);
                  margin-right: f.px2vw(20);
                  margin-top: f.px2vw(5);
                }
                
                &::before {
                  content: '';
                  position: absolute;
                  top: 50%;
                  transform: translateY(-50%);
                  left: 0;
                  background: url("../img/common/youtube-icon.png");
                  background-size: cover;
                  background-repeat: no-repeat;
                  
                  @include g.mq(md) {
                    width: f.px2vw(28,1920);
                    height: f.px2vw(19.5,1920);    
                  }
                  
                  @include g.mq(sm) {
                    width: f.px2vw(13);
                    height: f.px2vw(9);
                  }
                }
              }
              
              /*# animation
              ==============================================================*/
              &.fade-next-wrap {
                bottom: f.px2vw(-50,1920);
                
                /*# animation
                ================*/
                animation-name:fade-next-wrap;
                animation-delay: 2.5s;
                animation-duration:1s;
                animation-timing-function: cubic-bezier(0.22, 1, 0.36, 1);
                animation-fill-mode:forwards; 
              }
              @include g.mq(md) {
                @keyframes fade-next-wrap{
                  0% {
                    bottom: f.px2vw(-50,1920);
                    opacity: 0;
                  }
                  
                  100% {
                    bottom: 0;
                    opacity: 1;
                  }
                }
              }
              @include g.mq(sm) {
                @keyframes fade-next-wrap{
                  0% {
                    bottom: f.px2vw(-50);
                    opacity: 0;
                  }
                  
                  100% {
                    bottom: 0;
                    opacity: 1;
                  }
                }
              }
            }
          }
          
          .box-img {
            cursor: pointer;
            
            @include g.mq(md) {
              flex: 0 0 f.px2vw(694.5,1920);
              margin-left: f.px2vw(43,1920);
              margin-top: f.px2vw(22,1920); 
              padding-bottom: f.px2vw(54,1920);   
            }
            
            @include g.mq(sm) {
              width: 100%;
              margin-top: f.px2vw(25);
              padding-right: f.px2vw(10);
            }
            
            img {
              width: 100%;
              
              @include g.mq(md) {
                border-radius: f.px2vw(16,1920);
              }
              
              @include g.mq(sm) {
                border-radius: f.px2vw(16);
              }
            }
          }
        }
        
        .box-more {
          position: absolute;
          right: 0;
          display: flex;
          justify-content: flex-end;
          
          @include g.mq(md) {
            margin-right: f.px2vw(4,1920);
            top: f.px2vw(433,1920);
          }
          
          @include g.mq(sm) {
            margin-right: f.px2vw(15);
            bottom: f.px2vw(-27);
          }
          
          .view-more-link {
            cursor: pointer;
            
            @include g.mq(md) {
              font-size: f.px2vw(28,1920);
            }
            
          }
        }
        
        &:first-child {
          margin-top: 0;
        }
      }
    }
  }
}