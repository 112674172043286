@use "../global" as g;

/* ==========================================================================
    base
========================================================================== */

html {
  height: 100%;
}

body {
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;

  position: relative;
  width: 100%;
  min-width: 320px;
  margin: 0 auto;
  color: #fff;
  font-size: px2vw(14);
  font-family: g.$font-sans;
  font-weight: bold;
  line-height: 1;
  -webkit-text-size-adjust: 100%;

  @include g.mq(md) {
    font-size: 1.6rem;
  };

  &::-webkit-scrollbar {
    display:none;
  }
}

.bg-fixed{
  @include g.mq(md) {
    min-height: 100%;
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center center;
    background-attachment: fixed;

    &.bg-fixed-1 {
      background-image: url("../img/common/base_bg_01.png");
    }
  
    &.bg-fixed-2 {
      background-image: url("../img/common/base_bg_02.png");
    }
  }
  
  @include g.mq(sm) {
    height: auto ;
    position: relative;
    &__attachment{
      position: absolute;
      left: 0;
      top: 0;
      display: block;
      z-index: -1;
      height: 100%;
      width: 100%;
      @include g.mq(sm) {
        background: #000;
      }
      &__clip{
        position: relative;
        overflow: hidden;
        clip-path: inset(0 0 0 0);
        height: 100%;
      }
      img{
        position: fixed;
        width: 100%;
        height: auto;
        left: 0;
        top: 0;
        z-index: -1;
        pointer-events: none;
        @include g.mq(sm) {
          top: 50%;
          transform: translateY(-50%);
        }
      }
    }
  }
}

picture {
  display: block;
}

.cd-scrolling-bg {
  min-height: 100%;
}

a,button {
  color: inherit;
  cursor: pointer;

  &:hover {
    opacity: 0.7;
    transition: opacity .3s;
  }
}

.link-img{
  display: block;
  overflow: hidden;

  &:hover {
      opacity: 1;
      background: #000;
      
      img {
          opacity: 0.8;
          transition: opacity .3s;
      }
  }

  img {
      transition: opacity .3s;
  }
}


img {
  max-width: 100%;
}

em {
  font-style: normal;
}

button {
  outline: none;
  border: 0;
  background-color: transparent;
}