@use "../global" as g;
@use 'function' as f;

/*# スクロール禁止
================*/
.scroll-ban {
  height: 100vh;
  overflow-y: hidden;
}


/*# タイトル
================*/
.cmn-title {
  font-family: g.$font-vietnam;
  font-weight: 100;
  letter-spacing: -0.05em;
  color: #fff;
  position: relative;
  opacity: 0;
  
  @include g.mq(md) {
    font-size: f.px2vw(220,1920);
    padding-left: f.px2vw(206,1920);
    line-height: 0.79;
  }
  
  @include g.mq(sm) {
    font-size: f.px2vw(64);
    padding:0 f.px2vw(20);
  }
}

.cmn-sub__title {
  opacity: 0;
  font-family: g.$font-sans;
  font-weight: bold;
  letter-spacing: normal;
  
  @include g.mq(md) {
    font-size: f.px2vw(16,1920);
    padding-left: f.px2vw(15,1920);
  }
  
  @include g.mq(sm) {
    display: inline-block;
    font-size: f.px2vw(12);
  }
}

/*# animation
==============================================================*/
.fade-title {
  
  &::after {
    content: '';
    position: absolute;
    background-color: #C4C4C4;
    bottom: 0;
    
    @include g.mq(md) {
      height: f.px2vw(2,1920);
    }
    @include g.mq(sm) {
      height: f.px2vw(2);
    }
    /*# animation
    ================*/
    animation-name:fade-line;
    animation-delay: 0.5s;
    animation-duration:1s;
    animation-timing-function: cubic-bezier(0.22, 1, 0.36, 1);
    animation-fill-mode:forwards;
  }
  
  @keyframes fade-line{
    0% {
      width: 0%;
    }
    
    100% {
      width: 100%;
    }
  }
  
  .cmn-title {
    opacity: 0;
    position: relative;
    bottom: f.px2vw(-90,1920);
    /*# animation
    ================*/
    animation-name:fade-title;
    animation-delay: 1s;
    animation-duration:1s;
    animation-timing-function: cubic-bezier(0.22, 1, 0.36, 1);
    animation-fill-mode:forwards;
  }
  @keyframes fade-title{
    0% {
      opacity: 0;
      bottom: f.px2vw(-90,1920);
    }
    
    100% {
      opacity: 1;
      bottom: 0;
    }
  }
  
  .cmn-sub__title {
    position: relative;
    opacity: 0;
    bottom: f.px2vw(-90,1920);
    /*# animation
    ================*/
    animation-name:fade-sub-title;
    animation-delay: 1.2s;
    animation-duration:1s;
    animation-timing-function: cubic-bezier(0.22, 1, 0.36, 1);
    animation-fill-mode:forwards;
  }
  @keyframes fade-sub-title{
    0% {
      bottom: f.px2vw(-90,1920);
      opacity: 0;
    }
    
    100% {
      bottom: 0;
      opacity: 1;
    }
  }
}

/*# pagetop
==============================================================*/
.pagetop {
  position: absolute;
  left: 0;
  right: 0;
  border-radius: 50%;
  z-index: 10;
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 0 auto;
  cursor: pointer;
  border: 2px solid rgba(255,255,255,0.6);
  
  @include g.mq(md) {
    width: f.px2vw(102,1920);
    height: f.px2vw(102,1920);
    bottom: f.px2vw(377,1920);
  }
  
  @include g.mq(sm) {
    width: f.px2vw(48);
    height: f.px2vw(48);
    bottom: f.px2vw(192);
  }
  
  .pagetop-icon {
    display: flex;
    align-items: center;
    justify-content: center;
    
    @include g.mq(md) {
      width: f.px2vw(35,1920);
    }
    
    @include g.mq(sm) {
      width: f.px2vw(15);
    }
    
    picture {
      display: flex;
    }
  }
}

/*# 共通アニメーション
==============================================================*/
@include g.mq(md) {
  @keyframes fade-bottom{
    0% {
      opacity: 0;
      bottom: -50px;
    }
    
    100% {
      opacity: 1;
      bottom: 0;
    }
  }
}
@include g.mq(sm) {
  @keyframes fade-bottom{
    0% {
      opacity: 0;
      bottom: f.px2vw(-50);
    }
    
    100% {
      opacity: 1;
      bottom: 0;
    }
  }
}

.view-more-link {
  display: inline-block;
  font-family: "Be Vietnam Pro", sans-serif;
  font-weight: 500;
  position: relative;
  border-bottom: 1px solid #fff;
  
  @include g.mq(md) {
    font-size: f.px2vw(28,1920);
    padding-left: f.px2vw(20,1920);
    padding-bottom: f.px2vw(5,1920);
  }
  
  @include g.mq(sm) {
    font-size: f.px2vw(12);
    padding-left: f.px2vw(14);
  }
  
  &::before {
    content: '';
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    left: 0;
    background: url("../img/common/arrow_icon_01.png");
    background-size: cover;
    background-repeat: no-repeat;
    
    @include g.mq(md) {
      width: f.px2vw(17,1920);
      height: f.px2vw(9,1920);    
    }
    
    @include g.mq(sm) {
      width: f.px2vw(10);
      height: f.px2vw(5);
    }
  }
}

/*==============================================================
# fadeIn
==============================================================*/
.fadeIn_up {
  opacity: 0;
  bottom: -50px;
}

.fadeIn_up.is-show {
  
  /*# animation
  ================*/
  animation-name:fadeIn;
  animation-delay: .5s;
  animation-duration:1s;
  animation-timing-function: cubic-bezier(0.22, 1, 0.36, 1);
  animation-fill-mode:forwards;
}

@include g.mq(md) {
  @keyframes fadeIn{
    0% {
      opacity: 0;
      bottom: -50px;
    }
    
    100% {
      opacity: 1;
      bottom: 0;
    }
  }
}
@include g.mq(sm) {
  @keyframes fadeIn{
    0% {
      opacity: 0;
      bottom: f.px2vw(-50);
    }
    
    100% {
      opacity: 1;
      bottom: 0;
    }
  }
}