@use "../global" as g;
@use "../foundation" as f;

.modal-content {
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  overflow-y: scroll;
  z-index: 50;
  background-color: rgba(0,0,0,.79);
  
  .content-close {
    position: fixed;
    cursor: pointer;
    z-index: 20;
    
    @include g.mq(md) {
      top: 43px;
      right: 43px;
      width: 87px;
      height: 87px;
    }
    
    @media screen and (min-width: 768px) and (max-width: 1205px) {
      top: f.px2vw(43,1205);
      right: f.px2vw(43,1205);
      width: f.px2vw(87,1205);
      height: f.px2vw(87,1205);
    }
    
    @include g.mq(sm) {
      top: f.px2vw(20);
      right: f.px2vw(20);
      width: f.px2vw(32);
      height: f.px2vw(32);
    }
    
    &::before {
      content: '';
      position: absolute;
      z-index: 60;
      background-image: url("../img/common/close_icon.png");
      background-size: cover;
      background-repeat: no-repeat;
      
      @include g.mq(md) {
        width: 87px;
        height: 87px;
      }
      
      @media screen and (min-width: 768px) and (max-width: 1205px) {
        width: f.px2vw(87,1205);
        height: f.px2vw(87,1205);
      }
      
      @include g.mq(sm) {
        width: f.px2vw(32);
        height: f.px2vw(32);
      }
    }
  }
  
  .content-box {
    
    @include g.mq(md) {
      max-width: 1200px;
      margin: 125px auto;
    }
    
    @media screen and (min-width: 768px) and (max-width: 1205px) {
      padding: 0 20px;
    }
    
    @include g.mq(sm) {
      margin: f.px2vw(82) 0;
    }
    
    &__wrap {
      position: relative;
      opacity: 0;
      
      @include g.mq(md) {
        display: flex;
      }
      
      @include g.mq(sm) {
        padding: 0 f.px2vw(20);
      }
      
      .wrap-img {
        
        @include g.mq(md) {
          flex: 0 0 526px;
          margin-right: 95px;
        }
        
        @include g.mq(sm) {
          padding: 0 f.px2vw(33);
        }
        
        @media screen and (min-width: 768px) and (max-width: 1205px) {
          flex: 0 0 f.px2vw(526,1205);
        }
        
        img {
          
          @include g.mq(md) {
            border-radius: 20px;
          }
          
          @media screen and (min-width: 768px) and (max-width: 1205px) {
            border-radius: f.px2vw(20,1205);
          }
          
          @include g.mq(sm) {
            border-radius: f.px2vw(20);
          }
        }
      }
      
      .wrap-message {
        
        @include g.mq(md) {
          flex: 0 1 100%;
        }
        
        @include g.mq(sm) {
          margin-top: f.px2vw(36);
        }
        
        &__en {
          
          @include g.mq(md) {
            font-size: 52px;
          }
          
          
          @include g.mq(sm) {
            font-size: f.px2vw(36);
          }
        }
        
        &__name {
          
          @include g.mq(md) {
            font-size: 36px;
            margin-top: 25px;
          }
          
          @media screen and (min-width: 768px) and (max-width: 1205px) {
            font-size: f.px2vw(36,1205);
          }
          
          @include g.mq(sm) {
            font-size: f.px2vw(20);
            margin-top: f.px2vw(15);
          }
          
          span {
            display: block;
            
            @include g.mq(md) {
              font-size: 18px;
              line-height: 1.6;
              margin-top: 8px;
            }
            
            @include g.mq(sm) {
              font-size: f.px2vw(14);
              margin-top: f.px2vw(5);
            }
          }
        }
        
        &__txt {
          font-weight: 400;
          
          @include g.mq(md) {
            font-size: 18px;
            line-height: 2;
            margin-top: 24px;
          }
          
          @include g.mq(sm) {
            font-size: f.px2vw(12);
            line-height: 1.5;
            margin-top: f.px2vw(15);
          }

          .right {
            display: block;
            text-align: right;
            margin-top: 10px;

          }
        }
        
        &__career {
          font-weight: 400;
          
          @include g.mq(md) {
            font-size: 18px;
            line-height: 2;
            margin-top: 24px;
          }
          
          @include g.mq(sm) {
            font-size: f.px2vw(12);
            line-height: 1.5;
            margin-top: f.px2vw(15);
          }
          
          .career-box {
            margin-top: 50px;
            
            @include g.mq(sm) {
              margin-top: f.px2vw(25);
            }
            
            &__dl {
              display: flex;
            }
            
            &__dt {
              margin-right: 20px;
              flex: 0 0 auto;
              
              @include g.mq(sm) {
                nav-right: f.px2vw(15);
              }
            }
            &__dd {
              
              a {
                text-decoration: underline;
              }
            }
            
            &:first-child {
              margin-top: 0;
            }
          }
        }
      }
      
      &.modal-content-box {
        opacity: 0;
        bottom: -50px;
        
        /*# animation
        ================*/
        animation-name:fade-bottom;
        animation-delay: 0.5s;
        animation-duration:1s;
        animation-timing-function: cubic-bezier(0.22, 1, 0.36, 1);
        animation-fill-mode:forwards;
      }
    }
    
    &__video {
      position: relative;
      opacity: 0;
      
      @include g.mq(md) {
        margin-top: 70px;
      }
      
      @include g.mq(sm) {
        margin: f.px2vw(44) f.px2vw(20) 0;
      }
      
      &.modal-video-box {
        opacity: 0;
        bottom: -50px;
        
        /*# animation
        ================*/
        animation-name:fade-bottom;
        animation-delay: 1s;
        animation-duration:1s;
        animation-timing-function: cubic-bezier(0.22, 1, 0.36, 1);
        animation-fill-mode:forwards;
      }
      
      img {
        width: 100%;
      }
      
      .video-txt {
        font-weight: 400;
        
        @include g.mq(md) {
          font-size: 18px;
          line-height: 2;
          margin-top: 12px;
        }
        
        @include g.mq(sm) {
          font-size: f.px2vw(12);
          line-height: 1.3;
          margin-top: f.px2vw(10);
        }
      }
    }
    
    &__gallery {
      position: relative;
      opacity: 0;
      bottom: -50px;
      
      @include g.mq(md) {
        display: grid;
        gap: 50px;
        margin-top: 50px;
        padding-bottom: 59px;
      }
      
      @include g.mq(sm) {
        margin: f.px2vw(39) f.px2vw(20) 0;
        padding-bottom: f.px2vw(40);
      }
      
      .gallery-item {
        
        @include g.mq(sm) {
          margin-top: f.px2vw(29);
        }
        
        img {
          width: 100%;
        }
        
        video {
          width: 100%;
        }
        
        &__txt {
          font-weight: 400;
          word-wrap: break-word;
          
          @include g.mq(md) {
            font-size: 18px;
            line-height: 2;
            margin-top: 12px;
          }
          
          @include g.mq(sm) {
            font-size: f.px2vw(12);
            line-height: 1.3;
            margin-top: f.px2vw(10);
          }
          
          a {
            word-wrap: break-word;
            text-decoration: underline;
          }
        }

        // カラム数
        &__column1-2 {
          @include g.mq(md) {
            grid-column: 1/2;
          }
        }
        &__column1-3 {
          @include g.mq(md) {
            grid-column: 1/3;
          }
        }
        &__column2-3 {
          @include g.mq(md) {
            grid-column: 2/3;
          }
        }
        // 行数
        &__row1-2 {
          @include g.mq(md) {
            grid-row: 1/2;    
          }
        }
        &__row1-3 {
          @include g.mq(md) {
            grid-row: 1/3;    
          }
        }
        &__row2-3 {
          @include g.mq(md) {
            grid-row: 2/3;    
          }
        }
        &__row3-4 {
          @include g.mq(md) {
            grid-row: 3/4;    
          }
        }
        &__row4-5 {
          @include g.mq(md) {
            grid-row: 4/5;    
          }
        }
      }
      
      &.modal-gallery-line {
        &::after {
          content: '';
          position: absolute;
          background-color: #C4C4C4;
          bottom: 0;
          
          @include g.mq(md) {
            height: f.px2vw(2,1920);
          }
          @include g.mq(sm) {
            height: f.px2vw(2);
          }
          /*# animation
          ================*/
          animation-name:fade-line;
          animation-delay: .5s;
          animation-duration:1s;
          animation-timing-function: cubic-bezier(0.22, 1, 0.36, 1);
          animation-fill-mode:forwards;
        }
      }
      
      &.modal-gallery-box {
        
        /*# animation
        ================*/
        animation-name:fade-bottom;
        animation-delay: .5s;
        animation-duration:1s;
        animation-timing-function: cubic-bezier(0.22, 1, 0.36, 1);
        animation-fill-mode:forwards;
      }
    }
    
    &__list {
      display: flex;
      justify-content: space-between;
      position: relative;
      opacity: 0;
      
      @include g.mq(md) {
        max-width: 972px;
        margin: 70px auto 0;
      }
      
      @include g.mq(sm) {
        flex-wrap: wrap;
        margin-top: f.px2vw(20);
        padding: 0 f.px2vw(20);
      }
      
      .list-item {
        
        @include g.mq(md) {
          width: 31.28%;
        }
        
        @include g.mq(sm) {
          width: 47.16%;
          margin-top: f.px2vw(20);
        }
        
        &__name {
          
          @include g.mq(md) {
            font-size: 28px;
            margin-top: 21px;
          }
          
          @include g.mq(sm) {
            font-size: f.px2vw(18);
            margin-top: f.px2vw(12);
          }
          
          span {
            display: block;
            
            @include g.mq(md) {
              font-size: 14px;
              margin-top: 14px;
            }
            
            @include g.mq(sm) {
              font-size: f.px2vw(10);
              margin-top: f.px2vw(11);
            }
          }
        }
      }
      
      &.modal-list-box {
        opacity: 0;
        bottom: -50px;
        
        /*# animation
        ================*/
        animation-name:fade-bottom;
        animation-delay: 1s;
        animation-duration:1s;
        animation-timing-function: cubic-bezier(0.22, 1, 0.36, 1);
        animation-fill-mode:forwards;
      }
    }
    
    /*# ギャラリーモーダル
    ==============================================================*/
    
    &.gallery-modal {
      
      @include g.mq(md) {
        margin: 0 auto;
        display: flex;
        align-items: center;
        justify-content: center;
        height: 100vh;
      }
    }
    
    &__img {
      position: relative;
      opacity: 0;
      text-align: center;
      margin: 0 auto;
      
      @include g.mq(sm) {
        padding: 0 20px;
      }

      .img-box {

        @include g.mq(md) {
          display: flex;
          justify-content: space-between;
        }

        picture {

          @include g.mq(md) {
            width: 47%;
          }

          &+picture {

            @include g.mq(sm) {
              margin-top: f.px2vw(30);
            }
          }
        }
      }
      
      .img-txt {
        font-weight: 400;
        
        @include g.mq(md) {
          font-size: 18px;
          line-height: 1.4;
          margin-top: 12px;
        }
        
        @include g.mq(sm) {
          font-size: f.px2vw(12);
          line-height: 1.3;
          margin-top: f.px2vw(10);
        }
        
        a {
          text-decoration: underline;
        }
      }
      
      img {
        
        @include g.mq(md) {
          height: 75vh;
          object-fit: contain;
        }
      }
      
      video {
        @include g.mq(md) {
          height: 75vh;
          object-fit: contain;
        }
        
        @include g.mq(sm) {
          width: 100%;
        }
      }
      
      &.modal-img-box {
        opacity: 0;
        bottom: -50px;
        
        /*# animation
        ================*/
        animation-name:fade-bottom;
        animation-delay: .5s;
        animation-duration:1s;
        animation-timing-function: cubic-bezier(0.22, 1, 0.36, 1);
        animation-fill-mode:forwards;
      }
    }
  }
}