@use "../global" as g;
@use "../foundation" as f;

/* ==========================================================================
header
========================================================================== */
header {
  display: flex;
  align-items: center;
  justify-content: space-between;
  position: fixed;
  width: 100%;
  z-index: 20;
  background: rgba(0,0,0,0.9);
  
  @include g.mq(md) {
    padding: f.px2vw(20,1920) f.px2vw(80,1920) f.px2vw(20,1920) f.px2vw(40,1920);
  }
  
  @include g.mq(sm) {
    display: none;
  }
  
  .header-logo {
    
    @include g.mq(md) {
      width: f.px2vw(95,1920);
    }
  }
  
  .header-nav {
    
    @include g.mq(sm) {
      display: none;
    }
    
    .nav-list {
      display: flex;
      align-items: center;
      justify-content: space-between;
      
      .list-item {
        margin-right: f.px2vw(80,1920);
        
        &__link {
          display: block;
          font-family: g.$font-vietnam;
          font-size: f.px2vw(22,1920);
          font-weight: bold;
          color: #fff;
        }
        
        &.comming {
          pointer-events: none;
          
          .list-item__link {
            color: #7B7B7B;
          }
        }
        
        &:last-child {
          margin-right: 0;
        }
      }
    }
  }
}


// ハンバーガーメニュー
.header-humberger {
  height: f.px2vw(62);
  
  @include g.mq(md) {
    display: none;
  }
  
  .header-head {
    padding-left: f.px2vw(20);
    height: f.px2vw(62);
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    z-index: 30;  
    display: flex;
    align-items: center;
    justify-content: space-between;
    background: #000;
    
    &__logo {
      width: f.px2vw(40);
      position: relative;
      z-index: 30;    
    }
    
    &__icon {
      width: f.px2vw(32);
      height: f.px2vw(32);
      margin-right: f.px2vw(17);
      position: relative;
      z-index: 30; 
      
      &::before {
        content: '';
        position: absolute;
        width: f.px2vw(28);
        height: f.px2vw(2);
        background-color: #fff;
        top: 40%;
        transition: top .2s .2s,transform .2s,-webkit-transform .2s;
      }
      
      &::after {
        content: '';
        position: absolute;
        width: f.px2vw(28);
        height: f.px2vw(2);
        background-color: #fff;
        top: 64%;
        transition: top .2s .2s,transform .2s,-webkit-transform .2s;
      }
      
      &.open {
        &::before {
          top: 50%;
          transform: rotate(-45deg);
          transition: top .2s,transform .2s .2s,-webkit-transform .2s .2s;
        }
        &::after {
          top: 50%;
          transform: rotate(45deg);
          transition: top .2s,transform .2s .2s,-webkit-transform .2s .2s;
        }
      }
    }
  }
  
  .header-menu {
    display: none;
    position: fixed;
    top: 0;
    left: 0;
    z-index: 20;
    width: 100%;
    height: -webkit-fill-available;
    background-color: #000;
    overflow-y: scroll;
    border-top: 1px solid #C4C4C4;
    
    .menu-list {
      margin-top: f.px2vw(74);
      padding-bottom: f.px2vw(30);
      
      .list-item {
        border-bottom: 1px solid #C4C4C4;
        position: relative;
        opacity: 0;
        bottom: f.px2vw(-50);
        transition: bottom .2s;
        
        &__link {
          display: block;
          font-family: g.$font-vietnam;
          font-weight: bold;
          font-size: f.px2vw(22);
          line-height: 1.27;
          padding: f.px2vw(30) 0;
          padding-left: f.px2vw(67);
        }
        
        &.comming {
          pointer-events: none;
          
          .list-item__link {
            color: #7B7B7B;
          }
        }
        
        &.action {
          bottom: 0;
          opacity: 1;
        }
        
        &.humberger-fade-item01 {
          transition: bottom .8s .1s,opacity .8s .1s;
        }
        &.humberger-fade-item02 {
          transition: bottom .8s .2s,opacity .8s .2s;
        }
        &.humberger-fade-item03 {
          transition: bottom .8s .3s,opacity .8s .3s;
        }
        &.humberger-fade-item04 {
          transition: bottom .8s .4s,opacity .8s .4s;
        }
        &.humberger-fade-item05 {
          transition: bottom .8s .5s,opacity .8s .5s;
        }
        &.humberger-fade-item06 {
          transition: bottom .8s .6s,opacity .8s .6s;
        }
        &.humberger-fade-item07 {
          transition: bottom .8s .7s,opacity .8s .7s;
        }
        &.humberger-fade-item08 {
          transition: bottom .8s .8s,opacity .8s .8s;
        }
        &.humberger-fade-item09 {
          transition: bottom .8s .9s,opacity .8s .9s;
        }
        
        &:last-child {
          border-bottom: none;
        }
      }
    }
  }
}