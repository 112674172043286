@use "../global" as g;
@use "../foundation" as f;

#gallery {
  
  @include g.mq(md) {
    margin-bottom: 66px;
  }
  
  @include g.mq(sm) {
    margin-bottom: f.px2vw(47);
  }
  
  .gallery-wrap {
    
    @include g.mq(md) {
      padding-top: 190px;
    }
    
    @include g.mq(sm) {
      margin-top: f.px2vw(34);
    }
    
    &__title {
      position: relative;
    }
    
    &__content {
      opacity: 0;
      position: relative;
      
      @include g.mq(md) {
        max-width: 1200px;
        margin: 100px auto 0;
      }
      
      @media screen and (min-width: 768px) and (max-width: 1205px) {
        padding: 0 f.px2vw(20,1205);
      }
      
      @include g.mq(sm) {
        padding: 0 f.px2vw(10);
        margin-top: f.px2vw(33);
      }
      
      .content-box {
        cursor: pointer;
        
        @include g.mq(md) {
          width: 30.86%;
          margin:0 f.px2vw(15,1920) f.px2vw(30,1920);
          border-radius: f.px2vw(10,1920);
        }
        
        @media screen and (min-width: 768px) and (max-width: 1205px) {
          width: 30.6%;
        }
        
        @include g.mq(sm) {
          width: 42%;
          margin:0 f.px2vw(10) f.px2vw(20);
        }
        
        img {
          
          @include g.mq(md) {
            border-radius: f.px2vw(10,1920);
          }
          
          @include g.mq(sm) {
            border-radius: f.px2vw(10);
          }
        }
      }
      
      &.fade-gallery-wrap {
        opacity: 0;
        bottom: -50px;
        
        /*# animation
        ================*/
        animation-name:gallery-fade-content;
        animation-delay: 1.5s;
        animation-duration:1s;
        animation-timing-function: cubic-bezier(0.22, 1, 0.36, 1);
        animation-fill-mode:forwards;
      }
      
      @include g.mq(md) {
        @keyframes gallery-fade-content{
          0% {
            opacity: 0;
            bottom: -50px;
          }
          
          100% {
            opacity: 1;
            bottom: 0;
          }
        }
      }
      @include g.mq(sm) {
        @keyframes gallery-fade-content{
          0% {
            opacity: 0;
            bottom: f.px2vw(-50);
          }
          
          100% {
            opacity: 1;
            bottom: 0;
          }
        }
      }
    }
  }
  
}