@use "../global" as g;
@use "../foundation" as f;

/* ==========================================================================
top
========================================================================== */
#top {
  
  /*# MV
  ==============================================================*/
  .mv {
    
    .mv-wrap {
      position: relative;
      
      &::before {
        content: '';
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        background: #000;
        z-index: 0;
      }
      
      &__video {
        position: relative;
        
        video {
          width: 100%;
        }
        
        &::after {
          content: "";
          position: absolute;
          left: 0;
          bottom: f.px2vw(-331,1920);
          background: linear-gradient(to bottom, #000 20%, transparent);
          width: 100%;
          height: f.px2vw(400,1920);
        }
      }
    }
    
    .mv-img {
      position: relative;
      margin: 0 auto;
      
      @include g.mq(md) {
        width: 90%;
      }
      
      @include g.mq(sm) {
        width: 100%;
      }
    }

    .mv-bunner {
      @include g.mq(md) {
        width: 55%;
        margin: 85px auto 0;    
      }
      
      @include g.mq(sm) {
        width: 100%;
        padding: 0 f.px2vw(20);
        margin-top: f.px2vw(37);
      }
    }
  
  }
  
  /*# program
  ==============================================================*/
  .program {
    
    @include g.mq(md) {
      margin-top: f.px2vw(325,1920);
    }
    
    @include g.mq(sm) {
      margin-top: f.px2vw(48);
    }
    
    .program-wrap {
      position: relative;
      
      &__title {
        position: relative;
        
        .cmn-title {
          
          @include g.mq(md) {
            padding-left: f.px2vw(199,1920);
          }
        }
        
        .cmn-sub__title {
          @include g.mq(sm) {
            padding-left: f.px2vw(6);
          }
        }
      }
      
      &__video {
        position: relative;
        z-index: 1;
        
        @include g.mq(md) {
          margin-top: f.px2vw(62,1920);
        }
        
        @include g.mq(sm) {
          margin-top: f.px2vw(10);
        }
        
        video {
          width: 100%;
        }
      }
      
      &__box {
        margin: 0 auto;
        
        @include g.mq(md) {
          position: relative;
          z-index: 2;
          transform: translateY(f.px2vw(-135,1920));  
          display: flex;
          align-items: flex-start;
          justify-content: space-between;
          max-width: f.px2vw(1465,1920);
        }
        
        @include g.mq(sm) {
          margin-top: f.px2vw(31);
          padding: 0 f.px2vw(20);
        }
        
        .box-title {
          
          @include g.mq(md) {
            flex: 0 0 f.px2vw(496.5,1920);
            margin: 0 f.px2vw(138,1920) 0 f.px2vw(40,1920);
            position: sticky;
            top: f.px2vw(300,1920);
          }
          
          @include g.mq(sm) {
            width: f.px2vw(168);
          }
          
          img {
            width: 100%;
          }
        }
        
        .message-box {
          opacity: 0;
          position: relative;
          
          @include g.mq(md) {
            flex: 0 1 100%;
            margin-top: f.px2vw(170,1920);
          }
          
          @include g.mq(sm) {
            margin-top: f.px2vw(46);
          }
          
          &__txt {
            font-weight: 400;
            
            @include g.mq(md) {
              font-size: f.px2vw(26,1920);
              line-height: 1.538;
            }
            
            @include g.mq(sm) {
              font-size: f.px2vw(12);
              font-weight: 400;
              line-height: 1.6;
              padding-right: f.px2vw(42);
            }
            
            &+.message-box__txt {
              
              @include g.mq(md) {
                margin-top: f.px2vw(36,1920);
              }
              
              @include g.mq(sm) {
                margin-top: f.px2vw(25);
              }
            }
          }
          
          &.fade-program-box {
            /*# animation
            ================*/
            animation-name:fade-program-box;
            animation-delay: 1s;
            animation-duration:1s;
            animation-timing-function: cubic-bezier(0.22, 1, 0.36, 1);
            animation-fill-mode:forwards;
          }
          
          @include g.mq(md) {
            @keyframes fade-program-box{
              0% {
                opacity: 0;
                bottom: f.px2vw(-50,1920);
              }
              
              100% {
                opacity: 1;
                bottom: 0;
              }
            }
          }
          @include g.mq(sm) {
            @keyframes fade-program-box{
              0% {
                opacity: 0;
                bottom: f.px2vw(-50);
              }
              
              100% {
                opacity: 1;
                bottom: 0;
              }
            }
          }
        }
      }
      
      &__next {
        @include g.mq(md) {
          max-width: f.px2vw(1479,1920);
          margin: f.px2vw(-47,1920) auto 0;
        }
        
        @include g.mq(sm) {
          margin-top: f.px2vw(63);
          padding: 0 f.px2vw(10) 0 f.px2vw(20);
        }
        
        .next-box {
          @include g.mq(md) {
            margin: f.px2vw(10,1920) auto 0;
            display: flex;
            justify-content: space-between;    
          }
          
          .box-message {
            
            @include g.mq(md) {
              flex: 0 1 100%;
              margin-top: f.px2vw(30,1920);
            }
            
            &__date {
              position: relative;
              opacity: 0;
              font-weight: bold;
              
              @include g.mq(md) {
                font-size: f.px2vw(28,1920);
                padding-left: f.px2vw(70,1920);
              }
              
              @include g.mq(sm) {
                font-size: f.px2vw(14);
              }
              
              &.fade-next-date {
                bottom: f.px2vw(-50,1920);
                
                /*# animation
                ================*/
                animation-name:fade-next-wrap;
                animation-delay: 1s;
                animation-duration:1s;
                animation-timing-function: cubic-bezier(0.22, 1, 0.36, 1);
                animation-fill-mode:forwards; 
              }
            }
            
            &__name {
              
              @include g.mq(md) {
                background: url("../img/top/next_bg_01.png");
                background-size: cover;
                background-repeat: no-repeat;    
                width: f.px2vw(1,1920);
                height: f.px2vw(191,1920);
                margin-top: f.px2vw(-16,1920);
              }
              
              @include g.mq(sm) {
                background: url("../img/top/sp/next_bg_01.png");
                background-size: cover;
                background-repeat: no-repeat;    
                width: f.px2vw(1);
                height: f.px2vw(77);
                transform: translateX(f.px2vw(-20));
                margin-top: f.px2vw(-4,1920);
              }
              
              
              .name-txt {
                opacity: 0;
                position: relative;
                
                @include g.mq(md) {
                  padding: f.px2vw(40,1920) 0 0 f.px2vw(142,1920);
                  font-size: f.px2vw(48,1920);
                  line-height: 1.2;
                  
                  bottom: f.px2vw(-50,1920);
                }
                
                @include g.mq(sm) {
                  // font-size: f.px2vw(22);
                  font-size: f.px2vw(16);
                  line-height: 1.375;
                  
                  margin-bottom: f.px2vw(-20);
                  padding: f.px2vw(15) 0 0 f.px2vw(43);    
                }
                
                .cat-name {
                  display: block;
                  line-height: 1.2;
                  
                  @include g.mq(md) {
                    font-size: f.px2vw(20,1920);
                    padding-bottom: f.px2vw(2,1920);
                  }
                  
                  @include g.mq(sm) {
                    font-size: f.px2vw(10);
                  }
                }
                
                .en-name {
                  display: block;
                  line-height: 1.6;
                  
                  @include g.mq(md) {
                    font-size: f.px2vw(18,1920);
                    padding-left: f.px2vw(11,1920);
                    margin-top: f.px2vw(5,1920);
                  }
                  
                  @include g.mq(sm) {
                    font-size: f.px2vw(10);
                    // margin-top: f.px2vw(5);
                  }
                }
              }
              
              /*# animation
              ==============================================================*/
              &.fade-next-name {
                width: 0px;
                
                /*# animation
                ================*/
                animation-name:fade-next-name__bg;
                animation-delay: 1.5s;
                animation-duration:1s;
                animation-timing-function: cubic-bezier(0.22, 1, 0.36, 1);
                animation-fill-mode:forwards; 
                
                .name-txt {
                  /*# animation
                  ================*/
                  animation-name:fade-next-name__txt;
                  animation-delay: 2s;
                  animation-duration:1s;
                  animation-timing-function: cubic-bezier(0.22, 1, 0.36, 1);
                  animation-fill-mode:forwards; 
                }
              }
              @include g.mq(md) {
                @keyframes fade-next-name__bg{
                  0% {
                    width: 0px;
                  }
                  
                  100% {
                    width: f.px2vw(742,1920);
                  }
                }
              }
              @include g.mq(sm) {
                @keyframes fade-next-name__bg{
                  0% {
                    width: 1px;
                  }
                  
                  100% {
                    width: f.px2vw(301);
                  }
                }
              }
              @include g.mq(md) {
                @keyframes fade-next-name__txt{
                  0% {
                    opacity: 0;
                    bottom: f.px2vw(-50,1920);
                  }
                  
                  100% {
                    opacity: 1;
                    bottom: 0;
                  }
                }
              }
              @include g.mq(sm) {
                @keyframes fade-next-name__txt{
                  0% {
                    opacity: 0;
                    bottom: f.px2vw(-50);
                  }
                  
                  100% {
                    opacity: 1;
                    bottom: 0;
                  }
                }
              }
            }
            
            &__wrap {
              position: relative;
              opacity: 0;
              
              @include g.mq(md) {
                padding-left: f.px2vw(69,1920);
                margin-top: f.px2vw(10,1920);
              }
              
              @include g.mq(sm) {
                margin-top: f.px2vw(10);
              }
              
              .wrap-title {
                @include g.mq(md) {
                  font-size: f.px2vw(30,1920);
                  line-height: 1.2;
                }
                
                @include g.mq(sm) {
                  font-size: f.px2vw(18);
                  line-height: 1.3;
                }
              }
              
              .wrap-txt {
                font-weight: 400;
                
                @include g.mq(md) {
                  font-size: f.px2vw(26,1920);
                  line-height: 1.538;
                  padding: f.px2vw(12,1920) f.px2vw(65,1920) 0 0;    
                }
                
                @include g.mq(sm) {
                  font-size: f.px2vw(12);
                  line-height: 1.5;
                  margin-top: f.px2vw(13);
                  padding-right: f.px2vw(60);
                }
              }
              
              /*# animation
              ==============================================================*/
              &.fade-next-wrap {
                bottom: f.px2vw(-50,1920);
                
                /*# animation
                ================*/
                animation-name:fade-next-wrap;
                animation-delay: 2.5s;
                animation-duration:1s;
                animation-timing-function: cubic-bezier(0.22, 1, 0.36, 1);
                animation-fill-mode:forwards; 
              }
              @include g.mq(md) {
                @keyframes fade-next-wrap{
                  0% {
                    bottom: f.px2vw(-50,1920);
                    opacity: 0;
                  }
                  
                  100% {
                    bottom: 0;
                    opacity: 1;
                  }
                }
              }
              @include g.mq(sm) {
                @keyframes fade-next-wrap{
                  0% {
                    bottom: f.px2vw(-50);
                    opacity: 0;
                  }
                  
                  100% {
                    bottom: 0;
                    opacity: 1;
                  }
                }
              }
            }
          }
          
          .box-img {
            position: relative;
            
            @include g.mq(md) {
              flex: 0 0 f.px2vw(694.5,1920);
              width: f.px2vw(694.5,1920);
              height: 100%;
              margin-left: f.px2vw(43,1920);
            }
            
            @include g.mq(sm) {
              width: 100%;
              padding-right: f.px2vw(10);
              margin-top: f.px2vw(24);
            }

            .swiper_artist {
              overflow: hidden;
            }

            .swiper-pagination__artist {
              display: flex;
              justify-content: center;
              position: absolute;
              left: 50%;
              transform: translateX(-50%);
              bottom: -30px;

              @include g.mq(sm) {
                bottom: f.px2vw(-20);
              }

              .swiper-pagination-bullet {
                background-color: #6C6C6C;
                width: 16px;
                height: 16px;
                opacity: 1;
                margin: 0 15px;

                @include g.mq(sm) {
                  width: f.px2vw(10);
                  height: f.px2vw(10);
                  margin: 0 f.px2vw(7);
                }
              }

              .swiper-pagination-bullet-active {
                background-color: #fff;
              }
            }

            picture {

              &+picture {
                padding-top: 30px;
              }
            }

            .movie {
              @include g.mq(md) {
                height: f.px2vw(390,1920);
              }
              
              @include g.mq(sm) {
                height: f.px2vw(188);
              }

              iframe {
                width: 100%;
                
                @include g.mq(md) {
                  border-radius: f.px2vw(16,1920);
                }
                
                @include g.mq(sm) {
                  border-radius: f.px2vw(16);
                }
              }
            }
          }
        }
      }
      
      &__new {
        
        @include g.mq(md) {
          width: 55%;
          margin: 85px auto 0;    
        }
        
        @include g.mq(sm) {
          width: 100%;
          padding: 0 f.px2vw(20);
          margin-top: f.px2vw(37);
        }
      }


    }
  }
  
  /*# artists
  ==============================================================*/
  .artists {
    overflow-x: hidden;
    position: relative;
    overflow-y: hidden;
    
    @include g.mq(md) {
      margin-top: f.px2vw(218,1920);
    }
    
    @include g.mq(sm) {
      margin-top: f.px2vw(115);
    }
    
    .artists-wrap {
      
      &__title {
        position: relative;
        
        .cmn-title {
          &::after {
            @include g.mq(sm) {
              bottom: f.px2vw(4);
            }
          }
        }
        .cmn-sub__title {
          @include g.mq(sm) {
            padding-left: f.px2vw(6);
          }
        }
      }
      
      &__content {
        
        @include g.mq(md) {
          display: grid;
          margin-top: f.px2vw(101,1920);    
        }
        
        @include g.mq(sm) {
          margin-top: f.px2vw(28);
        }
        
        .content-box {
          position: relative;
          z-index: 4;
          
          .box-link{
            display: block;
            width: 100%;
            height: 100%;
            overflow: hidden;
            
            &:hover {
              opacity: 1;
              
              img {
                opacity: 0.8;
                transition: opacity .3s;
              }
            }
            
            img {
              transition: opacity .3s;
            }
          }
          
          &__img {
            height: 100%;
            opacity: 0;
            
            picture {
              height: 100%;
              
              img {
                
                @include g.mq(md) {
                  height: 100%;
                  object-fit: cover;
                }
              }
            }
          }
          
          &__name {
            opacity: 0;
            position: absolute;
            font-family: g.$font-vietnam;
            font-weight: 700;
            font-style: italic;
            letter-spacing: -0.05em;
            color: #000;
            background: transparent !important;
            
            @include g.mq(md) {
              font-size: f.px2vw(72,1920);
              bottom: f.px2vw(25,1920);
              right: f.px2vw(25,1920);    
            }
            
            @include g.mq(sm) {
              font-size: f.px2vw(28);
              line-height: 1.2;
              bottom: f.px2vw(9);
              right: f.px2vw(21);   
            }
            
            span {
              display: block;
              font-family: g.$font-sans;
              font-style: normal;
              text-align: right;
              font-weight: bold;
              letter-spacing: normal;
              
              @include g.mq(md) {
                font-size: f.px2vw(32,1920);
                padding-top: f.px2vw(5,1920);
              }
              
              @include g.mq(sm) {
                font-size: f.px2vw(14);
              }
            }
            
            &.comming {
              color: #fff;
            }
            
            &.white-txt {
              color: #fff;
              &__sp {
                @include g.mq(sm) {
                  color: #fff;
                }
              }
            }
          }
          
          
          &.item01 {
            z-index: 3;
            
            @include g.mq(md) {
              grid-column: 1/2;
              grid-row: 1/4;
              width: f.px2vw(804,1920); 
              height: f.px2vw(930,1920);   
            }
            
            @include g.mq(sm) {
              margin-top: 0;
            }
            
            .content-box__name {
              
              @include g.mq(md) {
                font-size: f.px2vw(72,1920);
                font-weight: 800;
              }
              
              @include g.mq(sm) {
                font-size: f.px2vw(28);
                bottom: f.px2vw(15);
              }
              
              span {
                
                @include g.mq(md) {
                  font-size: f.px2vw(32,1920);
                  font-weight: bold;
                }
                
                @include g.mq(sm) {
                  font-size: f.px2vw(14);
                }
              }
            }
          }
          &.item02 {
            z-index: 4;
            
            @include g.mq(md) {
              grid-column: 2/3;
              grid-row: 1/2;
              width: f.px2vw(1116,1920); 
              height: f.px2vw(310,1920);      
            }
          }
          &.item03 {
            
            @include g.mq(md) {
              grid-column: 2/3;
              grid-row: 2/3;
              width: f.px2vw(1116,1920); 
              height: f.px2vw(310,1920);     
            }
          }
          &.item04 {
            
            @include g.mq(md) {
              grid-column: 2/4;
              grid-row: 3/4;
              width: f.px2vw(1116,1920); 
              height: f.px2vw(310,1920);         
            }
          }
          
          //一人ver
          &.item05 {
            
            @include g.mq(md) {
              grid-column: 4/4;
              grid-row: 4/4;
              width: 100%;    
            }
          }

          // 二人version
          &.item06 {
            z-index: 3;

            @include g.mq(md) {
                grid-column: 1/2;
                grid-row: 1/4;
                width: f.px2vw(960,1920); 
                height: f.px2vw(930,1920);   
            }

            @include g.mq(sm) {
                margin-top: 0;
            }
          }
          &.item07 {
            z-index: 4;

            @include g.mq(md) {
                grid-column: 2/3;
                grid-row: 1/4;
                width: 100%; 
                height: f.px2vw(930,1920);      
            }
          }
          
          //アニメーション
          &.active01 {
            overflow: hidden;
            
            .content-box__img {
              opacity: 0;
              animation-name:widthslide;
              animation-delay: 1.3s;
              animation-duration:2s;
              animation-timing-function: cubic-bezier(0.22, 1, 0.36, 1);
              animation-fill-mode:forwards;
            }
            .content-box__name {
              opacity: 0;
              animation-name:nameslide;
              animation-delay: 1.3s;
              animation-duration:2s;
              animation-timing-function: cubic-bezier(0.22, 1, 0.36, 1);
              animation-fill-mode:forwards;
            }
            
            &::before {
              content: '';
              position: absolute;
              top: 0;
              left: 0;
              z-index: 3;
              background-color: #FA005B;
              width: 0%;
              height: 100%;
              
              animation-name:beforepink01;
              animation-delay: .5s;
              animation-duration:2s;
              animation-fill-mode:forwards;
              animation-timing-function: cubic-bezier(0.22, 1, 0.36, 1);
            }                        
            
            &::after {
              content: '';
              position: absolute;
              top: 0;
              left: 0;
              z-index: 3;
              background-color: #16ADFF;
              width: 0%;
              height: 100%;
              
              animation-name:afterblue01;
              animation-delay: 1.5s;
              animation-duration:1.5s;
              animation-fill-mode:forwards;
              animation-timing-function: cubic-bezier(0.22, 1, 0.36, 1);
            }
            
          }
          &.active02 {
            
            .content-box__img {
              opacity: 0;
              animation-name:widthslide;
              animation-delay: 2.6s;
              animation-duration:2s;
              animation-timing-function: cubic-bezier(0.22, 1, 0.36, 1);
              animation-fill-mode:forwards;
            }
            .content-box__name {
              opacity: 0;
              animation-name:nameslide;
              animation-delay: 2.6s;
              animation-duration:2s;
              animation-timing-function: cubic-bezier(0.22, 1, 0.36, 1);
              animation-fill-mode:forwards;
            }
            
            &::before {
              content: '';
              position: absolute;
              top: 0;
              z-index: 3;
              background-color: #FA005B;
              width: 0%;
              height: 100%;
              
              animation-name:beforepink;
              animation-delay: 1.9s;
              animation-duration:2s;
              animation-fill-mode:forwards;
              animation-timing-function: cubic-bezier(0.22, 1, 0.36, 1);
            }
            
            
            
            &::after {
              content: '';
              position: absolute;
              top: 0;
              left: 0;
              z-index: 3;
              background-color: #16ADFF;
              width: 0%;
              height: 100%;
              
              animation-name:afterblue;
              animation-delay: 2.6s;
              animation-duration:1.5s;
              animation-fill-mode:forwards;
              animation-timing-function: cubic-bezier(0.22, 1, 0.36, 1);
            }
          }
          &.active03 {
            
            .content-box__img {
              opacity: 0;
              animation-name:widthslide;
              animation-delay: 2.8s;
              animation-duration:2s;
              animation-timing-function: cubic-bezier(0.22, 1, 0.36, 1);
              animation-fill-mode:forwards;
            }
            .content-box__name {
              opacity: 0;
              animation-name:nameslide;
              animation-delay: 2.8s;
              animation-duration:2s;
              animation-timing-function: cubic-bezier(0.22, 1, 0.36, 1);
              animation-fill-mode:forwards;
            }
            
            &::before {
              content: '';
              position: absolute;
              top: 0;
              z-index: 3;
              background-color: #FA005B;
              width: 0%;
              height: 100%;
              
              animation-name:beforepink;
              animation-delay: 2.1s;
              animation-duration:2s;
              animation-fill-mode:forwards;
              animation-timing-function: cubic-bezier(0.22, 1, 0.36, 1);
            }
            
            &::after {
              content: '';
              position: absolute;
              top: 0;
              left: 0;
              z-index: 3;
              background-color: #16ADFF;
              width: 0%;
              height: 100%;
              
              animation-name:afterblue;
              animation-delay: 2.8s;
              animation-duration:1.5s;
              animation-fill-mode:forwards;
              animation-timing-function: cubic-bezier(0.22, 1, 0.36, 1);
            }
          }
          &.active04 {
            
            .content-box__img {
              opacity: 0;
              animation-name:widthslide;
              animation-delay: 3s;
              animation-duration:2s;
              animation-timing-function: cubic-bezier(0.22, 1, 0.36, 1);
              animation-fill-mode:forwards;
            }
            .content-box__name {
              opacity: 0;
              animation-name:nameslide;
              animation-delay: 3s;
              animation-duration:2s;
              animation-timing-function: cubic-bezier(0.22, 1, 0.36, 1);
              animation-fill-mode:forwards;
            }
            
            &::before {
              content: '';
              position: absolute;
              top: 0;
              z-index: 3;
              background-color: #FA005B;
              width: 0%;
              height: 100%;
              
              animation-name:beforepink;
              animation-delay: 2.3s;
              animation-duration:2s;
              animation-fill-mode:forwards;
              animation-timing-function: cubic-bezier(0.22, 1, 0.36, 1);
            }
            
            &::after {
              content: '';
              position: absolute;
              top: 0;
              left: 0;
              z-index: 3;
              background-color: #16ADFF;
              width: 0%;
              height: 100%;
              
              animation-name:afterblue;
              animation-delay: 3s;
              animation-duration:1.5s;
              animation-fill-mode:forwards;
              animation-timing-function: cubic-bezier(0.22, 1, 0.36, 1);
            }
          }
          //一人ver
          &.active05 {
            overflow: hidden;
            
            .content-box__img {
              opacity: 0;
              will-change: animation;
              animation-name:widthslide;
              animation-delay: 2.6s;
              animation-duration:2s;
              animation-timing-function: cubic-bezier(0.22, 1, 0.36, 1);
              animation-fill-mode:forwards;
            }
            
            .content-box__name {
              opacity: 0;
              will-change: animation;
              animation-name:nameslide;
              animation-delay: 2.6s;
              animation-duration:2s;
              animation-timing-function: cubic-bezier(0.22, 1, 0.36, 1);
              animation-fill-mode:forwards;
              
              @include g.mq(md) {
                font-size: f.px2vw(90,1920);
                font-weight: 800;
                right: f.px2vw(55,1920);
              }
              
              @include g.mq(sm) {
                font-size: f.px2vw(28);
                bottom: f.px2vw(15);
              }
              
              span {
                
                @include g.mq(md) {
                  font-size: f.px2vw(32,1920);
                  font-weight: bold;
                  margin-right: f.px2vw(15,1920);    
                }
                
                @include g.mq(sm) {
                  font-size: f.px2vw(14);
                }
              }
            }
            
            &::before {
              content: '';
              position: absolute;
              top: 0;
              left: 0;
              z-index: 3;
              background-color: #FA005B;
              width: 0%;
              height: 100%;
              
              will-change: animation;
              animation-name:beforepink05;
              animation-delay: 1.8s;
              animation-duration:2s;
              animation-fill-mode:forwards;
              animation-timing-function: cubic-bezier(0.22, 1, 0.36, 1);
            }                        
            
            &::after {
              content: '';
              position: absolute;
              top: 0;
              left: 0;
              z-index: 3;
              background-color: #16ADFF;
              width: 0%;
              height: 100%;
              
              will-change: animation;
              animation-name:afterblue05;
              animation-delay: 2.8s;
              animation-duration:1.5s;
              animation-fill-mode:forwards;
              animation-timing-function: cubic-bezier(0.22, 1, 0.36, 1);
            }
            
          }
          /*# keyframes
          ==============================================================*/
          @keyframes widthslide{
            0% {
              opacity: 0;
            }
            
            20% {
              opacity: 1;
              background: #000;
            }
            
            100% {
              opacity: 1;
              background: #000;
            }
          }
          @keyframes nameslide{
            0% {
              opacity: 0;
            }
            
            20% {
              opacity: 1;
            }
            
            100% {
              opacity: 1;
            }
          }
          /*# item01
          ================*/
          @include g.mq(md) {
            @keyframes beforepink01{
              0% {
                width: 0%;
                left: 0;
              }
              
              50% {
                left: 0;
                width: 100%;
              }
              
              100% {
                left: f.px2vw(1216,1920);
              }
            }
          }
          @include g.mq(sm) {
            @keyframes beforepink01{
              0% {
                width: 0%;
                left: 0;
              }
              
              50% {
                left: 0;
                width: 100%;
              }
              
              100% {
                left: f.px2vw(375);
              }
            }
          }
          @include g.mq(md) {
            @keyframes afterblue01{
              0% {
                width: 0%;
                left: 0;
              }
              
              20% {
                left: 0;
                width: 100%;
              }
              
              100% {
                left: f.px2vw(1216,1920);
              }
            }
          }
          @include g.mq(sm) {
            @keyframes afterblue01{
              0% {
                width: 0%;
                left: 0;
              }
              
              20% {
                left: 0;
                width: 100%;
              }
              
              100% {
                left: f.px2vw(375);
              }
            }
          }
          /*# 他アイテム
          ===========*/
          @include g.mq(md) {
            @keyframes beforepink{
              0% {
                width: 0%;
                left: 0;
              }
              
              50% {
                left: 0;
                width: 100%;
              }
              
              100% {
                left: f.px2vw(1216,1920);
              }
            }
          }
          @include g.mq(sm) {
            @keyframes beforepink{
              0% {
                width: 0%;
                left: 0;
              }
              
              50% {
                left: 0;
                width: 100%;
              }
              
              100% {
                left: f.px2vw(375);
              }
            }
          }                        
          @include g.mq(md) {
            @keyframes afterblue{
              0% {
                width: 0%;
                left: 0;
              }
              
              20% {
                left: 0;
                width: 100%;
              }
              
              100% {
                left: 1216px;
              }
            }
          }
          @include g.mq(sm) {
            @keyframes afterblue{
              0% {
                width: 0%;
                left: 0;
              }
              
              20% {
                left: 0;
                width: 100%;
              }
              
              100% {
                left: f.px2vw(375);
              }
            }
          }
          /*#一人ver
          ================*/
          @keyframes beforepink05{
            0% {
              width: 0%;
              left: 0;
            }
            
            50% {
              left: 0;
              width: 100%;
            }
            
            100% {
              left: 100vw;
            }
          }
          @keyframes afterblue05{
            0% {
              width: 0%;
              left: 0;
            }
            
            20% {
              left: 0;
              width: 100%;
            }
            
            100% {
              left: 100vw;
            }
          }
        }
      }
    }
  }
  
  /*# gallery
  ==============================================================*/
  .gallery {
    position: relative;
    
    @include g.mq(md) {
      padding: f.px2vw(239,1920) 0 f.px2vw(118,1920);
    }
    
    @include g.mq(sm) {
      padding: f.px2vw(98) 0 f.px2vw(40);
    }
    
    .artists-view__more {
      position: absolute;
      
      @include g.mq(md) {
        top: f.px2vw(29,1920);
        right: f.px2vw(57,1920);
      }
      
      @include g.mq(sm) {
        top: f.px2vw(8);
        right: f.px2vw(25);
      }
    }
    
    .gallery-view__more {
      display: block;
      text-align: right;
      
      @include g.mq(md) {
        margin: f.px2vw(29,1920) f.px2vw(57,1920) 0 0;
      }
      
      @include g.mq(sm) {
        margin: f.px2vw(9) f.px2vw(25) 0 0;
      }
    }
    
    &::before {
      content: '';
      position: absolute;
      left: 0;
      height: 2px;
      background-color: #C4C4C4;
      
      @include g.mq(md) {
        top: f.px2vw(119,1920);
      }
      
      @include g.mq(sm) {
        top: f.px2vw(50);
      }
    }
    
    // &::after {
      //     content: '';
      //     position: absolute;
      //     height: 2px;
      //     width: 0;
      //     bottom: 0;
      //     background-color: #C4C4C4;
      // }
      
      .gallery-wrap {
        position: relative;
        opacity: 0;
        
        @include g.mq(md) {
          bottom: f.px2vw(-50,1920);
        }
        
        &__title {
          position: absolute;
          top: 50%;
          transform: translateY(-50%);
          left: 0;
          right: 0;
          font-family: g.$font-vietnam;
          text-align: center;
          font-weight: 500;
          letter-spacing: -0.05em;
          
          @include g.mq(md) {
            font-size: f.px2vw(120,1920);
          }
          
          @include g.mq(sm) {
            font-size: f.px2vw(48);
          }
        }
        
        &.fade-gallery-wrap {
          /*# animation
          ================*/
          animation-name:fade-gallery-wrap;
          animation-delay: 1.5s;
          animation-duration:1s;
          animation-timing-function: cubic-bezier(0.22, 1, 0.36, 1);
          animation-fill-mode:forwards;
        }
        @keyframes fade-gallery-wrap{
          0% {
            opacity: 0;
            bottom: f.px2vw(-50,1920);
          }
          
          100% {
            opacity: 1;
            bottom: 0;
          }
        }
      }
      
      &.fade-gallery-line {
        
        &::before {
          /*# animation
          ================*/
          animation-name:fade-gallery-line;
          animation-delay: 1s;
          animation-duration:1s;
          animation-timing-function: cubic-bezier(0.22, 1, 0.36, 1);
          animation-fill-mode:forwards;
        }  
        &::after {
          /*# animation
          ================*/
          animation-name:fade-gallery-line;
          animation-delay: 2s;
          animation-duration:1s;
          animation-timing-function: cubic-bezier(0.22, 1, 0.36, 1);
          animation-fill-mode:forwards;
        }  
      }
      
      @keyframes fade-gallery-line{
        0% {
          width: 0%;
        }
        
        100% {
          width: 100%;
        }
      }
    }
    
  /*# buy
  ==============================================================*/
  .buy {
    position: relative;
    
    @include g.mq(md) {
      padding: f.px2vw(111,1920) 0 f.px2vw(111,1920);
    }
    
    @include g.mq(sm) {
      padding: f.px2vw(49) 0 f.px2vw(57);
    }
    
    .buy-wrap {
      position: relative;
      opacity: 0;
      border: 1px solid #D9D9D9;
      
      @include g.mq(md) {
        max-width: f.px2vw(693,1920);
        height: f.px2vw(180,1920);
        margin: 0 auto;
        display: flex;
        bottom: f.px2vw(-50,1920);
      }
      
      @include g.mq(sm) {
        height: f.px2vw(77);
        margin: 0 f.px2vw(30);
      }
      
      &::before {
        content: '';
        position: absolute;
        background-image: url("../img/top/buy_img_02.png");
        background-size: cover;
        background-repeat: no-repeat;
        left: 50%;
        transform: translateX(-50%);
        
        @include g.mq(md) {
          top: f.px2vw(15,1920);
          width: f.px2vw(684,1920);
          height: f.px2vw(211.5,1920);
        }
        
        @include g.mq(sm) {
          top: f.px2vw(7);
          width: f.px2vw(289);
          height: f.px2vw(90);
        }
      }
      
      &__message {
        color: #fff;
        background-color: #000;
        display: flex;
        align-items: center;
        justify-content: center;
        
        
        @include g.mq(md) {
          flex-direction: column;    
          flex: 0 1 100%;
        }
        
        @include g.mq(sm) {
          height: 100%;
        }
        
        .message-title {
          font-family: g.$font-vietnam;
          font-weight: 100;
          letter-spacing: -0.05em;
          
          @include g.mq(md) {
            font-size: f.px2vw(114,1920);
          }
          
          @include g.mq(sm) {
            font-size: f.px2vw(57);
            text-align: center;
          }
        }
        
        .message-txt {
          font-size: f.px2vw(20,1920);
          line-height: 1.45;
          
          @include g.mq(md) {
            font-size: f.px2vw(20,1920);
          }
          
          @include g.mq(sm) {
            font-size: f.px2vw(11);
            padding: 0 0 f.px2vw(2) f.px2vw(5);
          }
        }
      }
      
      
      &.fade-buy-wrap {
        /*# animation
        ================*/
        animation-name:fade-buy-wrap;
        animation-delay: 1s;
        animation-duration:1s;
        animation-timing-function: cubic-bezier(0.22, 1, 0.36, 1);
        animation-fill-mode:forwards;
      }
      @keyframes fade-buy-wrap{
        0% {
          opacity: 0;
          bottom: f.px2vw(-50,1920);
        }
        
        100% {
          opacity: 1;
          bottom: 0;
        }
      }
    }
  }
  
  /*# specialists
  ==============================================================*/
  .specialists {
    overflow: hidden;
    
    
    .specialists-wrap {
      
      &__title {
        position: relative;
        
        @include g.mq(sm) {
          padding-bottom: f.px2vw(9);
        }
        
        .cmn-sub__title {
          @include g.mq(sm) {
            display: block;
            margin-top: f.px2vw(9);
          }
        }
      }
      
      &__list {
        display: flex;
        
        @include g.mq(md) {
          margin-top: f.px2vw(73,1920);
        }
        
        @include g.mq(sm) {
          margin-top: f.px2vw(22);
        }
        
        .list-item {
          position: relative;
          
          &__name {
            position: absolute;
            font-weight: 300;
            writing-mode: vertical-rl; 
            
            @include g.mq(md) {
              font-size: f.px2vw(72,1920);
              right: f.px2vw(22,1920);
              bottom: f.px2vw(14,1920);
            }
            
            @include g.mq(sm) {
              font-size: f.px2vw(21);
              right: f.px2vw(7);
              bottom: f.px2vw(8);
            }
          }
          
          &__profile {
            position: absolute;
            z-index: 2;
            top: 53.5%;
            transform: translateY(-50%);
            display: flex;
            align-items: center;
            justify-content: center;
            background-color: #000;
            
            @include g.mq(md) {
              width: f.px2vw(373,1920);
              height: f.px2vw(171,1920);
              right: f.px2vw(-20,1920);
            }
            
            .profile-txt {
              
              @include g.mq(md) {
                font-size: f.px2vw(36,1920);
              }
              
              span {
                display: block;
                
                @include g.mq(md) {
                  font-size: f.px2vw(18,1920);
                  margin-top: f.px2vw(12,1920);
                }
              }
            }
          }
        }
      }
    }
  }
  
  /*# collector
  ==============================================================*/
  .collector {
    position: relative;
    box-sizing: border-box;
    
    @include g.mq(md) {
      padding-top: f.px2vw(219,1920);
    }
    
    @include g.mq(sm) {
      padding: f.px2vw(121) f.px2vw(20) 0;
    }
    
    .specialists-view__more {
      position: absolute;
      
      @include g.mq(md) {
        top: f.px2vw(29,1920);
        right: f.px2vw(57,1920);
      }
      
      @include g.mq(sm) {
        top: f.px2vw(8);
        right: f.px2vw(25);
      }
    }
    
    &::before {
      content: '';
      position: absolute;
      height: 2px;
      width: 0;
      left: 0;
      background-color: #C4C4C4;
      
      @include g.mq(md) {
        top: f.px2vw(105,1920);
      }
      
      @include g.mq(sm) {
        top: f.px2vw(61);
      }
    }
    
    
    .collector-content {
      @include g.mq(md) {
        max-width: f.px2vw(1160,1920);
        margin: 0 auto;
        margin-left: f.px2vw(380,1920);  
      }
      
      .collector-wrap {
        opacity: 0;
        position: relative;
        
        @include g.mq(md) {
          display: flex;
          justify-content: space-between;  
          margin-top: f.px2vw(38,1920);
        }
        
        &__img {
          position: relative;
          
          @include g.mq(md) {
            flex: 0 0 f.px2vw(341,1920);
            width: f.px2vw(341,1920);
            margin-right: f.px2vw(22,1920); 
            order: 0;   
          }
          
          @include g.mq(sm) {
            margin: f.px2vw(16) auto 0;
            // width: 70%;
            width: 100%;
          }

          .swiper_collector {
            overflow: hidden;
          }

          .swiper-pagination__collector {
            display: flex;
            justify-content: center;
            position: absolute;
            left: 50%;
            transform: translateX(-50%);
            bottom: -30px;

            @include g.mq(sm) {
              bottom: f.px2vw(-20);
            }

            .swiper-pagination-bullet {
              background-color: #6C6C6C;
              width: 16px;
              height: 16px;
              opacity: 1;
              margin: 0 15px;

              @include g.mq(sm) {
                width: f.px2vw(10);
                height: f.px2vw(10);
                margin: 0 f.px2vw(7);
              }
            }

            .swiper-pagination-bullet-active {
              background-color: #fff;
            }
          }
          
          img {
            width: 100%;
          }

          picture {

            &+picture {
              padding-top: 30px;
            }
          }
        }
        
        &__message {
          
          @include g.mq(md) {
            flex: 0 1 100%;
            order: 1;
          }
          
          .message-title {
            font-family: g.$font-vietnam;
            font-weight: 100;
            letter-spacing: -0.05em;
            
            @include g.mq(md) {
              font-size: f.px2vw(90,1920);
              margin-top: f.px2vw(-9,1920);
            }
            
            @include g.mq(sm) {
              font-size: f.px2vw(48);
            }
          }
          
          .message-txt {
            font-weight: 400;
            
            @include g.mq(md) {
              font-size: f.px2vw(26,1920);
              line-height: 1.538;
              margin: f.px2vw(15,1920) 0 0 f.px2vw(14,1920);
            }
            
            @include g.mq(sm) {
              font-size: f.px2vw(12);
              line-height: 1.6;
              margin-top: f.px2vw(21);
              padding-right: f.px2vw(10);
            }
          }
        }
        
        &.fade-collector-wrap {
          /*# animation
          ================*/
          animation-name:fade-collector-wrap;
          animation-delay: 1.5s;
          animation-duration:1s;
          animation-timing-function: cubic-bezier(0.22, 1, 0.36, 1);
          animation-fill-mode:forwards;
        }
        @keyframes fade-collector-wrap{
          0% {
            opacity: 0;
            bottom: f.px2vw(-50,1920);
          }
          
          100% {
            opacity: 1;
            bottom: 0;
          }
        }
      }
    }
    &.fade-collector-line {
      
      &::before {
        /*# animation
        ================*/
        animation-name:fade-collector-line;
        animation-delay: 1s;
        animation-duration:1s;
        animation-timing-function: cubic-bezier(0.22, 1, 0.36, 1);
        animation-fill-mode:forwards;
      }
      @keyframes fade-collector-line{
        0% {
          width: 0%;
        }
        
        100% {
          width: 100%;
        }
      }
    }
    
    .collector-view__more {
      display: block;
      text-align: right;
      
      @include g.mq(md) {
        margin: f.px2vw(20,1920) f.px2vw(4,1920) 0 0;
      }
      
      @include g.mq(sm) {
        margin: f.px2vw(29) f.px2vw(15) 0 0;
      }
      
      .view-more-link {
        cursor: pointer;
        
        @include g.mq(md) {
          font-size: f.px2vw(28,1920);
        }
      }
    }
  }
  
  /*# curators
  ==============================================================*/
  .curators {
    position: relative;
    
    @include g.mq(md) {
      padding-top: f.px2vw(111,1920);
      margin-top: f.px2vw(112,1920);
    }
    
    @include g.mq(sm) {
      margin-top: f.px2vw(61);
      padding: f.px2vw(59) 0 0 f.px2vw(20);
    }
    
    &::before {
      content: '';
      position: absolute;
      height: 2px;
      width: 0;
      left: 0;
      top: 0;
      background-color: #C4C4C4;
    }
    
    .curators-wrap {
      position: relative;
      opacity: 0;
      
      @include g.mq(md) {
        display: flex;
        align-items: center;
        justify-content: space-between;
        max-width: f.px2vw(1332,1920);
        margin: 0 0 f.px2vw(77,1920) f.px2vw(360,1920);  
        bottom: f.px2vw(-50,1920); 
      }
      
      &__instagram {
        overflow-y: scroll;
        -ms-overflow-style: none;    /* IE, Edge 対応 */
        scrollbar-width: none;       /* Firefox 対応 */
        background: #fff;
        
        @include g.mq(md) {
          flex: 0 0 f.px2vw(576,1920);
          height: f.px2vw(859,1920);
          margin-right: f.px2vw(49,1920); 
          border-radius: f.px2vw(21,1920);   
        }
        
        @include g.mq(sm) {
          width: f.px2vw(253);
          height: f.px2vw(381);
          margin-left: f.px2vw(38);
          border-radius: f.px2vw(21);
        }
        
        &::-webkit-scrollbar {
          display: none;
        }
        
        .elfsight-app-9f585fcc-e392-476c-8ff7-20afed4e3549 {
          background-color: #fff;
          
          @include g.mq(md) {
            border-radius: f.px2vw(21,1920);   
          }
          
          @include g.mq(sm) {
            border-radius: f.px2vw(21);
          }
          
          .eapps-instagram-feed-posts-view.eui-slider-slide {
            
            @include g.mq(md) {
              padding: f.px2vw(10,1920);
            }
            
            @include g.mq(sm) {
              padding: f.px2vw(10);
            }
          }
        }
        .eapps-instagram-feed-header-user-picture-wrapper {
          
          @include g.mq(sm) {
            flex: 0 0 48px;
          }
        }
      }
      
      &__message {
        
        @include g.mq(md) {
          flex: 0 1 100%;
        }
        
        @include g.mq(sm) {
          margin-top: f.px2vw(45);
        }
        
        .message-img {
          
          img {
            width: 100%;   
          }
        }
        
        .message-title {
          font-family: g.$font-vietnam;
          font-weight: 100;
          letter-spacing: -0.05em;
          
          @include g.mq(md) {
            font-size: f.px2vw(78,1920);
          }
          
          @include g.mq(sm) {
            font-size: f.px2vw(45);
            margin-top: f.px2vw(24);
          }
        }
        
        .message-box {
          
          @include g.mq(md) {
            margin-top: f.px2vw(28,1920);
          }
          
          @include g.mq(sm) {
            margin-top: f.px2vw(11);
          }
          
          &__title {
            
            @include g.mq(md) {
              font-size: f.px2vw(36,1920);
              line-height: 1.3;    
            }
            
            @include g.mq(sm) {
              font-size: f.px2vw(18);
              line-height: 1;    
            }
          }
          
          &__txt {
            font-weight: bold;
            
            @include g.mq(md) {
              font-size: f.px2vw(36,1920);
              line-height: 1.3;
            }
            
            @include g.mq(sm) {
              font-size: f.px2vw(18);
              line-height: 1.4;
            }
          }
        }
      }
      
      &.fade-curators-wrap {
        /*# animation
        ================*/
        animation-name:fade-curators-wrap;
        animation-delay: 1.5s;
        animation-duration:1s;
        animation-timing-function: cubic-bezier(0.22, 1, 0.36, 1);
        animation-fill-mode:forwards;
      }
      @keyframes fade-curators-wrap{
        0% {
          opacity: 0;
          bottom: f.px2vw(-50,1920);
        }
        
        100% {
          opacity: 1;
          bottom: 0;
        }
      }
    }
    
    &.fade-curators-line {
      
      &::before {
        /*# animation
        ================*/
        animation-name:fade-curators-line;
        animation-delay: 1s;
        animation-duration:1s;
        animation-timing-function: cubic-bezier(0.22, 1, 0.36, 1);
        animation-fill-mode:forwards;
      }
      
      @keyframes fade-curators-line{
        0% {
          width: 0%;
        }
        
        100% {
          width: 100%;
        }
      }
    }
  }
  
  /*# news
  ==============================================================*/
  .news {
    
    @include g.mq(md) {
      margin-top: f.px2vw(140,1920);
    }
    
    @include g.mq(sm) {
      margin-top: f.px2vw(69);
    }
    
    .news-wrap {
      
      &__title {
        position: relative;
        
        .cmn-title {
          &::after {
            @include g.mq(sm) {
              bottom: f.px2vw(4);
            }
          }
        }
        .cmn-sub__title {
          @include g.mq(md) {
            padding-left: f.px2vw(38,1920);
          }
          @include g.mq(sm) {
            display: inline-block;
            padding-left: f.px2vw(7);
          }
        }
      }
      
      &__content {
        position: relative;
        background: #000;
        opacity: 0;
        
        @include g.mq(md) {
          padding: f.px2vw(85,1920) 0 f.px2vw(120,1920);
        }
        
        @include g.mq(sm) {
          padding: f.px2vw(23) f.px2vw(40);
        }
        
        .content-list {
          
          @include g.mq(md) {
            display: flex;
            justify-content: space-between;
            flex-wrap: wrap;
          }
          
          @media screen and (min-width: 768px) and (max-width: 1201px) {
            padding: 0 f.px2vw(20,1920);
          }
          
          .list-item {
            
            @include g.mq(md) {
              width: 30.5%;
            }
            
            @include g.mq(sm) {
              width: 100%;
              margin-top: f.px2vw(28);
            }
            
            &__date {
              color: #6F6F6F;
              
              @include g.mq(md) {
                font-size: f.px2vw(24,1920);
                line-height: 1.25;
              }
              
              @include g.mq(sm) {
                font-size: f.px2vw(16);
                line-height: 1.875;
              }
            }
            
            &__title {
              display: block;
              text-decoration: underline;
              
              @include g.mq(md) {
                font-size: f.px2vw(16,1920);
                line-height: 1.5;
                margin-top: 15px;    
              }
              
              @include g.mq(sm) {
                font-size: f.px2vw(14);
                line-height: 1.714;
              }
            }
            
            &__txt {
              font-weight: 400;
              
              @include g.mq(md) {
                font-size: f.px2vw(16,1920);
                line-height: 1.5;
                margin-top: f.px2vw(15,1920);    
              }
              
              @include g.mq(sm) {
                font-size: f.px2vw(12);
                line-height: 1.6;
                margin-top: f.px2vw(12);
              }
            }
            
            &:first-child {
              margin-top: 0;
            }
          }
        }
        
        &.fade-news-content {
          /*# animation
          ================*/
          animation-name:fade-news-content;
          animation-delay: 1.5s;
          animation-duration:1s;
          animation-timing-function: cubic-bezier(0.22, 1, 0.36, 1);
          animation-fill-mode:forwards;
        }
        @keyframes fade-news-content{
          0% {
            opacity: 0;
            bottom: f.px2vw(-50,1920);
          }
          
          100% {
            opacity: 1;
            bottom: 0;
          }
        }
      }
    }
  }
  
  /*# other
  ==============================================================*/
  .other {
    
    @include g.mq(md) {
      padding-top: f.px2vw(134,1920);   
      padding-bottom: f.px2vw(134,1920);     
    }
    
    @include g.mq(sm) {
      padding-top: f.px2vw(69);
      padding-bottom: f.px2vw(69);
    }
    
    &::before {
      content: '';
      position: absolute;
      height: 2px;
      width: 0;
      left: 0;
      top: 0;
      background-color: #C4C4C4;
    }
    
    .other-wrap {
      
      @include g.mq(md) {
        display: flex;
        justify-content: space-between;
        max-width: f.px2vw(1200,1920);
        margin: 0 auto; 
      }
      
      &__widget {
        background: #fff;
        overflow-y: scroll;
        
        @include g.mq(md) {
          flex: 0 0 f.px2vw(563,1920);
          height: f.px2vw(842,1920);
          border-radius: f.px2vw(21,1920);    
        }
        
        @include g.mq(sm) {
          width: f.px2vw(258);
          height: f.px2vw(387);
          margin: 0 auto;
          border-radius: f.px2vw(21);    
        }
        
        .twitter-timeline {
          height: 100%;
        }
        
        iframe {
          
          @include g.mq(md) {
            border-radius: f.px2vw(21,1920);
          }
          
          @include g.mq(sm) {
            border-radius: f.px2vw(21);
          }
        }
      }
      
      &__bnr {
        
        @include g.mq(md) {
          flex: 0 1 100%;
        }
        
        @include g.mq(sm) {
          margin-top: f.px2vw(39);
          padding: 0 f.px2vw(20);
        }
        
        a {
          @include g.mq(md) {
            border-radius: f.px2vw(10,1920);
          }
        }
        
        .bnr-item {
          
          @include g.mq(md) {
            width: f.px2vw(600,1920);
            margin-top: f.px2vw(30,1920);
            margin-left: auto;    
          }
          
          @include g.mq(sm) {
            margin-top: f.px2vw(17);
          }
          
          &:first-child {
            margin-top: 0;
          }
          
          img {
            width: 100%;
            
            @include g.mq(md) {
              border-radius: f.px2vw(10,1920);
            }
            
            @include g.mq(sm) {
              border-radius: f.px2vw(10);
            }
          }
        }
      }
    }
    
    &.fade-other-line {
      
      &::before {
        /*# animation
        ================*/
        animation-name:fade-other-line;
        animation-delay: .5s;
        animation-duration:1s;
        animation-timing-function: cubic-bezier(0.22, 1, 0.36, 1);
        animation-fill-mode:forwards;
      }
      
      @keyframes fade-other-line{
        0% {
          width: 0%;
        }
        
        100% {
          width: 100%;
        }
      }
    }
  }
  
  /*# client
  ==============================================================*/
  .client {
    background-color: #000;
    
    @include g.mq(md) {
      margin-top: f.px2vw(118,1920);
    }
    
    @include g.mq(sm) {
      margin-top: f.px2vw(60);
    }
    
    .client-wrap {
      position: relative;
      
      &__img {
        position: relative;
        opacity: 0;
        background-color: #000;
        
        &.fade-client-img {
          opacity: 0;
          /*#  animation
          ================*/
          animation-name:fade-client-img;
          animation-delay: 0.5s;
          animation-duration:1s;
          animation-timing-function: cubic-bezier(0.22, 1, 0.36, 1);
          animation-fill-mode:forwards;
        }
        @include g.mq(md) {
          @keyframes fade-client-img{
            0% {
              bottom: f.px2vw(-25,1920);
              opacity: 0;
            }
            
            100% {
              bottom: 0;
              opacity: 1;
            }
          }
        }
        @include g.mq(sm) {
          @keyframes fade-client-img{
            0% {
              bottom: f.px2vw(-25);
              opacity: 0;
            }
            
            100% {
              bottom: 0;
              opacity: 1;
            }
          }
        }
      }
      
      &__title {
        position: absolute;
        font-family: g.$font-vietnam;
        font-weight: 100;
        letter-spacing: -0.05em;
        
        @include g.mq(md) {
          top: f.px2vw(59,1920);
          left: f.px2vw(206,1920);
          font-size: f.px2vw(90,1920);
        }
        
        @include g.mq(sm) {
          top: f.px2vw(24);
          left: f.px2vw(40);
          font-size: f.px2vw(48);
        }
      }
      
      &__txt {
        position: absolute;
        left: 0;
        right: 0;
        text-align: center;
        font-weight: 400;
        
        @include g.mq(md) {
          font-size: f.px2vw(16,1920);
          bottom: f.px2vw(98,1920);
        }
        
        @include g.mq(sm) {
          font-size: f.px2vw(12);
          bottom: f.px2vw(51);
        }
        
        &.fade-client-txt {
          opacity: 0;
          /*#  animation
          ================*/
          animation-name:fade-client-txt;
          animation-delay: 0.5s;
          animation-duration:1s;
          animation-timing-function: cubic-bezier(0.22, 1, 0.36, 1);
          animation-fill-mode:forwards;
        }
        @include g.mq(md) {
          @keyframes fade-client-txt{
            0% {
              bottom: f.px2vw(73,1920);
              opacity: 0;
            }
            
            100% {
              bottom: f.px2vw(98,1920);
              opacity: 1;
            }
          }
        }
        @include g.mq(sm) {
          @keyframes fade-client-txt{
            0% {
              bottom: f.px2vw(26);
              opacity: 0;
            }
            
            100% {
              bottom: f.px2vw(51);
              opacity: 1;
            }
          }
        }
      }
    }
  }
}