/* ==========================================================================
    variables
   ========================================================================== */

// font
$font-sans: 'Noto Sans JP', sans-serif;
$font-lato: 'Lato', sans-serif;
$font-menu-en: 'Montserrat', sans-serif;
$font-text-en: 'Oswald', sans-serif;
$font-vietnam: 'Be Vietnam Pro', sans-serif;

// color
$color-black: #040000;
$color-yellow: #FFBA00;
$color-cyan: #60BBD6;
$color-purple: #EB9EDA;
$color-blue: #8CACBA;
$color-green: #81C768;
$color-red: #FF0000;

$font-color: #707070;

