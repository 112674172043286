@use "../global" as g;
@use "../foundation" as f;

/*==============================================================
# contact
==============================================================*/

#contact {

  .contact-mv {
    background-image: url("../img/contact/fv.png");
    background-size: cover;
    background-repeat: no-repeat;
    background-position-x: center;
    width: 100%;

    @include g.mq(md) {
      height: 765px;
    }

    @include g.mq(sm) {
      position: relative;
      background-image: url("../img/contact/sp/fv.png");
      background-size: cover;
      background-repeat: no-repeat;
      background-position-x: center;
      height: f.px2vw(158);
    }

    .content {
      max-width: 1514px;
      margin: 0 auto;

      .message {

        @include g.mq(md) {
          padding-top: 302px;
        }
        @include g.mq(sm) {
          padding: f.px2vw(54) 0 0 f.px2vw(20);
        }

        .title {
          font-family: g.$font-vietnam;
          font-weight: 100;
          letter-spacing: -.05em;
          
          @include g.mq(md) {
            font-size: 130px;
          }
          @include g.mq(sm) {
            font-size: f.px2vw(50);
          }
          
        }

        .txt {
          @include g.mq(md) {
            width: 706px;
            padding: 22px 0 0 23px;
          }

          @include g.mq(sm) {
            position: absolute;
            width: 86%;
            left: 50%;
            transform: translateX(-50%);
            bottom: f.px2vw(-219);
          }
        }
      }
    }
  }

  .contact-wrap {

    @include g.mq(md) {
      margin-top: 109px;
    }

    @include g.mq(sm) {
      margin-top: f.px2vw(283);
    }

    .content {
      max-width: 1505px;
      margin: 0 auto;

      @media screen and (min-width: 768px) and (max-width: 1525px) {
        padding: 0 20px;
      }

      @include g.mq(sm) {
        padding: 0 f.px2vw(20);
      }

      .title {
        font-family: g.$font-vietnam;
        font-weight: 100;
        letter-spacing: -.05em;
        color: #fff;

        @include g.mq(md) {
          font-size: 86px;
        }
        @include g.mq(sm) {
          font-size: f.px2vw(36);
        }

        span {
          vertical-align: bottom;
          letter-spacing: 0em;

          @include g.mq(md) {
            font-size: 18px;
            padding-left: 23px;
            line-height: 2;
          }
          @include g.mq(sm) {
            display: block;
            font-size: f.px2vw(12);
            line-height: 1.6;
            margin-top: f.px2vw(11);
          }
        }
      }

      .list {
        max-width: 1200px;
        margin: 0 auto;

        @include g.mq(md) {
          display: flex;
          justify-content: space-between;
          flex-wrap: wrap;  
          margin-top: 71px;
        }
        @include g.mq(sm) {
          margin-top: f.px2vw(23);
        }

        .item {
          position: relative;

          @include g.mq(md) {
            width: 47.5%;
            margin-bottom: 150px;
          }

          @include g.mq(sm) {
            margin-bottom: f.px2vw(139);
          }

          .message {
            width: 95%;
            margin: 0 auto;
            position: absolute;
            left: 50%;
            transform: translateX(-50%);
            color: #fff;
            background-color: #000;

            @include g.mq(md) {
              display: flex;
              height: 148px;
              bottom: -50px;
              padding: 20px;
            }

            @media screen and (min-width: 768px) and (max-width: 1200px) {
              height: f.px2vw(148,1200);
              padding: f.px2vw(20,1200);
            }
            
            @include g.mq(sm) {
              width: 88%;
              padding: f.px2vw(21) f.px2vw(20) f.px2vw(21) f.px2vw(37);
              bottom: f.px2vw(-79);

              &::before {
                content: "";
                position: absolute;
                top: 50%;
                transform: translateY(-50%);
                left: f.px2vw(20);
                width: f.px2vw(2);
                height: 76%;
                background-color: #fff;
              }
            }

            .name {
              position: relative;
              line-height: 1.1;

              @include g.mq(md) {
                flex: 0 0 132px;
                font-size: 36px;
                padding: 10px 0 10px 15px;
              }

              @media screen and (min-width: 768px) and (max-width: 1200px) {
                flex: 0 0 f.px2vw(132,1200);
                font-size: f.px2vw(36,1200);
                padding: f.px2vw(10,1200) 0 f.px2vw(10,1200) f.px2vw(15,1200);
              }

              @include g.mq(sm) {
                font-size: f.px2vw(24);
                padding-bottom: f.px2vw(5);
                border-bottom: 1px solid #fff;
              }

              &::before {
                content: "";
                position: absolute;
                top: 50%;
                transform: translateY(-50%);
                background-color: #fff;

                @include g.mq(md) {
                  left: 0;
                  width: 4px;
                  height: 100%;
                }
                @include g.mq(sm) {
                  display: none;
                }
              }
            }

            .box {

              @include g.mq(md) {
                padding: 10px 0 10px 13px;
                border-left: 1px solid #fff;
              }

              @media screen and (min-width: 768px) and (max-width: 1200px) {
                padding: f.px2vw(10,1200) 0 f.px2vw(10,1200) f.px2vw(13,1200);
              }

              @include g.mq(sm) {
                margin-top: f.px2vw(5);
              }

              .txt {
                line-height: 1.5;

                @include g.mq(md) {
                  font-size: 16px;
                }

                @media screen and (min-width: 768px) and (max-width: 1200px) {
                  font-size: f.px2vw(16,1200);
                }

                @include g.mq(sm) {
                  font-size: f.px2vw(12);
                }
              }

              .link {
                position: relative;
                display: inline-block;
                font-weight: bold;

                @include g.mq(md) {
                  margin-top: 8px;
                  font-size: 24px;
                }

                @media screen and (min-width: 768px) and (max-width: 1200px) {
                  margin-top: f.px2vw(8,1200);
                  font-size: f.px2vw(24,1200);
                }

                @include g.mq(sm) {
                  font-size: f.px2vw(16);
                  margin-top: f.px2vw(5);
                }

                &::after {
                  content: "";
                  position: absolute;
                  top: 50%;
                  transform: translateY(-50%);
                  background-image: url("../img/contact/tab01.png");
                  background-size: cover;
                  background-repeat: no-repeat;

                  @include g.mq(md) {
                    right: -30px;
                    width: 20px;
                    height: 20px;
                  }

                  @media screen and (min-width: 768px) and (max-width: 1200px) {
                    right: f.px2vw(-30,1200);
                    width: f.px2vw(20,1200);
                    height: f.px2vw(20,1200);
                  }

                  @include g.mq(sm) {
                    right: f.px2vw(-20);
                    width: f.px2vw(14);
                    height: f.px2vw(14);
                  }
                }

                &.no-link {
                  &::after {
                    display: none;
                  }
                }
              }
            }

            .cat {
              position: absolute;
              left: 50%;
              transform: translateX(-50%);

              @include g.mq(md) {
                width: 237px;
                top: -38px;
              }

              @media screen and (min-width: 768px) and (max-width: 1200px) {
                width: f.px2vw(237,1200);
                top: f.px2vw(-38,1200);
              }

              @include g.mq(sm) {
                width: f.px2vw(138);
                top: f.px2vw(-22);
              }
            }
          }
        }
      }
    }
  }
}