@use "../global" as g;

/* ==========================================================================
  utility
  ========================================================================== */

/* ---------------------------------------------------------------------------
  show/hide
  --------------------------------------------------------------------------- */
// mobile
.sp {
  display: initial;

  @include g.mq(md) {
    display: none;
  };
}

// desktop
.pc {
  display: none;

  @include g.mq(md) {
    display: initial;
  };
}

/*===========================================
container
===========================================*/
.cmn-container {
  width: 100%;
  margin: 0 auto;

  &__md {
    max-width: 1200px;
  }

  &__sm {
    max-width: 1050px;
  }
}

