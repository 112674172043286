@use "../global" as g;
@use "../foundation" as f;

/*==============================================================
# specialists
==============================================================*/

#specialists {
  position: relative;
  
  @include g.mq(md) {
    margin-bottom: f.px2vw(112,1920);
  }
  
  @include g.mq(sm) {
    margin-bottom: f.px2vw(57);
  }
  
  .specialists-mv {
    @include g.mq(md) {
      padding-top: f.px2vw(126,1920);
    }
  }
  
  .specialists-wrap {
    
    @include g.mq(md) {
      margin-top: 110px;
    }
    
    @include g.mq(sm) {
      margin-top: f.px2vw(55);
    }
    
    &__title {
      position: relative;
      
      @include g.mq(sm) {
        padding-bottom: f.px2vw(9);
      }
      
      .cmn-sub__title {
        @include g.mq(sm) {
          display: block;
          margin-top: f.px2vw(9);
        }
      }
    }
    
    &__content {
      
      @include g.mq(md) {
        margin-top: 135px;
      }
      
      @include g.mq(sm) {
        margin-top: f.px2vw(65);
      }
      
      .content {
        
        .content-title {
          text-align: center;
          position: relative;
          opacity: 0;
          
          @include g.mq(md) {
            font-size: 48px;
          }
          
          @include g.mq(sm) {
            font-size: f.px2vw(24);
          }
          
          &.fade-content-title {
            /*# animation
            ================*/
            animation-name:specialists-wrap-fade;
            animation-delay: 1s;
            animation-duration:1s;
            animation-timing-function: cubic-bezier(0.22, 1, 0.36, 1);
            animation-fill-mode:forwards;
          }
        }
        
        .content-txt {
          text-align: center;
          position: relative;
          opacity: 0;
          
          @include g.mq(md) {
            font-size: f.px2vw(18,1920);
            line-height: 2;
            margin-top: f.px2vw(21,1920);
          }
          
          @include g.mq(sm) {
            font-size: f.px2vw(12);
            line-height: 1.6;
            margin-top: f.px2vw(20);
            padding: 0 f.px2vw(40);
          }
          
          &.fade-content-txt {
            /*# animation
            ================*/
            animation-name:specialists-wrap-fade;
            animation-delay: 1.2s;
            animation-duration:1s;
            animation-timing-function: cubic-bezier(0.22, 1, 0.36, 1);
            animation-fill-mode:forwards;
          }
        }
        
        @include g.mq(md) {
          @keyframes specialists-wrap-fade{
            0% {
              opacity: 0;
              bottom: f.px2vw(-50,1920);
            }
            
            100% {
              opacity: 1;
              bottom: 0;
            }
          }
        }
        @include g.mq(sm) {
          @keyframes specialists-wrap-fade{
            0% {
              opacity: 0;
              bottom: f.px2vw(-50);
            }
            
            100% {
              opacity: 1;
              bottom: 0;
            }
          }
        }
        
        .content-box {
          
          @include g.mq(md) {
            max-width: 1560px;
            margin: 118px 0 0 auto;
          }
          
          @include g.mq(sm) {
            margin-top: f.px2vw(65);
            padding: 0 f.px2vw(45);
          }
          
          &__item {
            opacity: 0;
            position: relative;
            
            @include g.mq(md) {
              display: flex;
              justify-content: space-between;
              margin-bottom: 88px;
              bottom: -50px;
            }
            
            @media screen and (min-width: 768px) and (max-width: 1565px) {
              padding: 0 20px;
            }
            
            @include g.mq(sm) {
              margin-bottom: f.px2vw(54);
              bottom: f.px2vw(-50);
            } 
            
            .item-img {
              
              @include g.mq(md) {
                flex: 0 0 31%;
                margin-right: 3.3%;
              }
              
              @include g.mq(sm) {
                width: 100%;
              }
              
              img {
                @include g.mq(md) {
                  border-radius: 20px;
                }
                
                @include g.mq(sm) {
                  border-radius: f.px2vw(20);
                }
              }
            }
            
            .item-message {
              
              @include g.mq(md) {
                flex: 0 1 100%;
              }
              
              &__sign {
                position: relative;
                
                @include g.mq(md) {
                  margin-top: 55px;
                }
                
                @include g.mq(sm) {
                  margin-top: f.px2vw(30);
                }
                
                &::before {
                  content: '';
                  position: absolute;
                  background-color: #fff;
                  width: 100%;
                  left: 0;
                  
                  @include g.mq(md) {
                    bottom: 6px;
                    height: 2px;
                  }
                  
                  @include g.mq(sm) {
                    bottom: f.px2vw(7);
                    height: f.px2vw(2);
                  }
                }
              }
              
              &__name {
                
                @include g.mq(md) {
                  font-size: 36px;
                  margin: 17px 0 0 38px;
                }
                
                @include g.mq(sm) {
                  font-size: f.px2vw(28);
                  margin-top: f.px2vw(15);
                }
                
                span {
                  display: block;
                  
                  @include g.mq(md) {
                    font-size: 18px;
                    line-height: 1.6;
                    margin-top: 8px;
                  }
                  
                  @include g.mq(sm) {
                    font-size: f.px2vw(15);
                    line-height: 1.53;
                    margin-top: f.px2vw(7);
                  }
                }
              }
              
              &__txt {
                font-weight: 400;
                -moz-osx-font-smoothing: grayscale;
                
                @include g.mq(md) {
                  width: 61.3%;
                  font-size: 18px;
                  line-height: 2;
                  margin: 24px 0 0 38px;
                }
                
                @media screen and (min-width: 768px) and (max-width: 1565px) {
                  width: 90%;
                }
                
                @include g.mq(sm) {
                  font-size: f.px2vw(12);
                  line-height: 1.6;
                  margin-top: f.px2vw(10);
                }
              }
            }
            
            &:last-child {
              margin-bottom: 0;
            }
            
            &--01 {
              .item-message__sign {
                img {
                  @include g.mq(md) {
                    width: 482px;
                  }
                  @include g.mq(sm) {
                    width: f.px2vw(257);
                  }
                }
              }
            }
            &--02 {
              .item-message__sign {
                img {
                  @include g.mq(md) {
                    width: 371px;
                  }
                  @include g.mq(sm) {
                    width: f.px2vw(257);
                  }
                }
              }
            }
            &--03 {
              .item-message__sign {
                img {
                  @include g.mq(md) {
                    width: 417.5px;
                  }
                  @include g.mq(sm) {
                    width: f.px2vw(253);
                  }
                }
              }
            }
            &--04 {
              .item-message__sign {
                img {
                  @include g.mq(md) {
                    width: 508px;
                  }
                  @include g.mq(sm) {
                    width: f.px2vw(257);
                  }
                }
              }
            }
            &--05 {
              .item-message__sign {
                img {
                  @include g.mq(md) {
                    width: 408.5px;
                  }
                  @include g.mq(sm) {
                    width: f.px2vw(257);
                  }
                }
              }
            }
            &--06 {
              .item-message__sign {
                img {
                  @include g.mq(md) {
                    width: 734px;
                  }
                  @include g.mq(sm) {
                    width: f.px2vw(284);
                  }
                }
              }
            }
            
            &.active-item {
              opacity: 0;
              bottom: -50px;
              
              /*# animation
              ================*/
              animation-name:specialists-content;
              animation-delay: .5s;
              animation-duration:1s;
              animation-timing-function: cubic-bezier(0.22, 1, 0.36, 1);
              animation-fill-mode:forwards;
            }
            
            @include g.mq(md) {
              @keyframes specialists-content{
                0% {
                  opacity: 0;
                  bottom: -50px;
                }
                
                100% {
                  opacity: 1;
                  bottom: 0;
                }
              }
            }
            @include g.mq(sm) {
              @keyframes specialists-content{
                0% {
                  opacity: 0;
                  bottom: f.px2vw(-50);
                }
                
                100% {
                  opacity: 1;
                  bottom: 0;
                }
              }
            }
          }
        }
      }
    }
  }
}