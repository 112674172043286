@use "../global" as g;
@use "../foundation" as f;

/*==============================================================
# collector
==============================================================*/

#collector {
  position: relative;
  
  @include g.mq(md) {
    margin-bottom: f.px2vw(112,1920);
  }
  
  @include g.mq(sm) {
    margin-bottom: f.px2vw(57);
  }
  
  .collector-mv {
    @include g.mq(md) {
      padding-top: f.px2vw(126,1920);
    }
  }
  
  .collector-wrap {
    
    @include g.mq(md) {
      margin-top: f.px2vw(53,1920);
    }
    
    @include g.mq(sm) {
      margin-top: f.px2vw(50);
    }
    
    &__title {
      position: relative;
      
      .cmn-sub__title {
        @include g.mq(sm) {
          margin: f.px2vw(9) 0 0 f.px2vw(5);
        }
      }
    }
    
    &__content {
      
      @include g.mq(md) {
        margin-top: f.px2vw(91,1920);
      }
      
      @include g.mq(sm) {
        margin-top: f.px2vw(55);
      }
      
      .content {
        
        .content-title {
          text-align: center;
          position: relative;
          opacity: 0;
          
          @include g.mq(md) {
            font-size: f.px2vw(48,1920);
          }
          
          @include g.mq(sm) {
            font-size: f.px2vw(24);
          }
          
          &.fade-content-title {
            /*# animation
            ================*/
            animation-name:specialists-wrap-fade;
            animation-delay: 1s;
            animation-duration:1s;
            animation-timing-function: cubic-bezier(0.22, 1, 0.36, 1);
            animation-fill-mode:forwards;
          }
        }
        
        .content-txt {
          text-align: center;
          position: relative;
          opacity: 0;
          
          @include g.mq(md) {
            font-size: f.px2vw(18,1920);
            line-height: 2;
            margin-top: f.px2vw(27,1920);
          }
          
          @include g.mq(sm) {
            font-size: f.px2vw(12);
            line-height: 1.6;
            margin-top: f.px2vw(20);
            padding: 0 f.px2vw(40);
          }
          
          &.fade-content-txt {
            /*# animation
            ================*/
            animation-name:specialists-wrap-fade;
            animation-delay: 1.2s;
            animation-duration:1s;
            animation-timing-function: cubic-bezier(0.22, 1, 0.36, 1);
            animation-fill-mode:forwards;
          }
        }
        
        @include g.mq(md) {
          @keyframes specialists-wrap-fade{
            0% {
              opacity: 0;
              bottom: f.px2vw(-50,1920);
            }
            
            100% {
              opacity: 1;
              bottom: 0;
            }
          }
        }
        @include g.mq(sm) {
          @keyframes specialists-wrap-fade{
            0% {
              opacity: 0;
              bottom: f.px2vw(-50);
            }
            
            100% {
              opacity: 1;
              bottom: 0;
            }
          }
        }
        
        .content-box {
          
          @include g.mq(md) {
            display: flex;
            justify-content: space-between;
            flex-wrap: wrap;
            max-width: 1200px;
            margin: 74px auto 0;
          }
          
          @include g.mq(sm) {
            margin-top: f.px2vw(53);
            padding: 0 f.px2vw(20);
          }
          
          &__item {
            opacity: 0;
            position: relative;
            
            @include g.mq(md) {
              width: 47.64%;
              margin-bottom: f.px2vw(90,1920);
              bottom: f.px2vw(-50,1920);
            }
            
            @media screen and (min-width: 768px) and (max-width: 1565px) {
              padding: 0 20px;
            }
            
            @include g.mq(sm) {
              margin-bottom: f.px2vw(60);
              bottom: f.px2vw(-50);
            } 
            
            .item-img {
              
              @include g.mq(sm) {
                width: 100%;
              }
              
              img {
                @include g.mq(md) {
                  border-radius: f.px2vw(20,1920);
                }
                
                @include g.mq(sm) {
                  border-radius: f.px2vw(20);
                }
              }
            }
            
            .item-message {
              
              @include g.mq(md) {
                margin-top: f.px2vw(20,1920);
              }
              
              @include g.mq(sm) {
                margin-top: f.px2vw(12);
              }
              
              &__txt {
                border-top: 1px solid #fff;
                border-bottom: 1px solid #fff;
                text-align: center;
                font-weight: 400;
                
                @include g.mq(md) {
                  font-size: f.px2vw(26,1920);
                  padding: f.px2vw(14,1920) 0;
                }
                
                @include g.mq(sm) {
                  font-size: f.px2vw(14);
                  padding: f.px2vw(13) 0;
                }
              }
              
              &__name {
                text-align: center;
                border-bottom: 1px solid #fff;
                font-weight: 400;
                
                @include g.mq(md) {
                  font-size: f.px2vw(62,1920);
                  padding: f.px2vw(16,1920) 0 f.px2vw(30,1920);
                }
                
                @include g.mq(sm) {
                  font-size: f.px2vw(36);
                  padding: f.px2vw(13) 0 f.px2vw(20);
                }
                
                span {
                  display: block;
                  font-weight: 400;
                  
                  @include g.mq(md) {
                    font-size: f.px2vw(26,1920);
                    line-height: 1.385;
                    margin-bottom: f.px2vw(9,1920);
                  }
                  
                  @include g.mq(sm) {
                    font-size: f.px2vw(14);
                    line-height: 1.286;
                    margin-bottom: f.px2vw(4);
                  }
                }
              }
            }
            
            &.active-item {
              opacity: 0;
              bottom: -50px;
              
              /*# animation
              ================*/
              animation-name:specialists-content;
              animation-delay: .5s;
              animation-duration:1s;
              animation-timing-function: cubic-bezier(0.22, 1, 0.36, 1);
              animation-fill-mode:forwards;
            }
            
            @include g.mq(md) {
              @keyframes specialists-content{
                0% {
                  opacity: 0;
                  bottom: -50px;
                }
                
                100% {
                  opacity: 1;
                  bottom: 0;
                }
              }
            }
            @include g.mq(sm) {
              @keyframes specialists-content{
                0% {
                  opacity: 0;
                  bottom: f.px2vw(-50);
                }
                
                100% {
                  opacity: 1;
                  bottom: 0;
                }
              }
            }
          }
        }
      }
    }
  }
}