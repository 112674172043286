@use "../global" as g;
@use "../foundation" as f;

/* ==========================================================================
footer
========================================================================== */
footer {
  position: relative;
  
  &::before {
    content: "";
    position: absolute;
    left: 0;
    background: linear-gradient(to top, #000 20%, transparent);
    width: 100%;
    
    @include g.mq(md) {
      top: f.px2vw(-140,1920);
      height: f.px2vw(180,1920);
    }
    @include g.mq(sm) {
      top: f.px2vw(-70);
      height: f.px2vw(90);
    }
  }
  
  .footer-wrap {
    position: relative;
    
    &__logo {
      position: absolute;
      left: 0;
      right: 0;
      margin: 0 auto;
      
      @include g.mq(md) {
        bottom: f.px2vw(114,1920);
        width: f.px2vw(356.5,1920);
      }
      
      @include g.mq(sm) {
        width: f.px2vw(170);
        bottom: f.px2vw(57);
      }
    }
  }
}